export default {
  "account": {
    "newEmailPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle adresse courriel"])},
    "newPhonePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau numéro de portable"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon compte"])},
    "requestChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demander un changement"])},
    "requestChangeEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demander un changement d'adresse e-mail"])},
    "requestChangePhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demander un changement de numéro de portable"])},
    "requestVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande de vérification"])},
    "requestVerificationEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demander la vérification de l'adresse e-mail"])},
    "requestVerificationPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demander la vérification du numéro de portable"])},
    "unverified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non vérifié"])},
    "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifié"])}
  },
  "analyses": {
    "addHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une analyse"])},
    "deleteHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l'analyse"])},
    "disabledHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de l'analyse"])},
    "editHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier l'analyse"])},
    "exportHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter les analyses"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses"])}
  },
  "analysisResultsTable": {
    "anteriority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antériorité"])},
    "calculatedResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats calculés"])},
    "enterPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez les résultats ici"])},
    "parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètre"])},
    "rawResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats bruts"])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référence"])},
    "referenceRangeCalculated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Références calculées"])},
    "referenceRangeRaw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Références brutes"])},
    "units": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unités"])},
    "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur"])}
  },
  "anes": {
    "ane1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse réalisée ultérieurement"])},
    "ane10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incident technique"])},
    "ane11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas d'autorisation parentale"])},
    "ane12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modification de la prescription par le biologiste"])},
    "ane2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse non réalisée à la demande du patient"])},
    "ane3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses hors nomenclatures non réalisée à la demande du patient"])},
    "ane4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Echantillon(s) manquant(s)"])},
    "ane5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions pré-analytique non respectées"])},
    "ane6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse déjà effectuée"])},
    "ane7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse annulée"])},
    "ane8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient non à jeun"])},
    "ane9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse non réalisée au laboratoire"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])}
  },
  "appearance": {
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
    "colorBlindMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode daltonien"])},
    "deactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactivé"])},
    "display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher"])},
    "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemple :"])},
    "exampleText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sars-cov non détecté"])},
    "fontSizes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tailles de police"])},
    "nightMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode nuit"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apparence"])}
  },
  "btns": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
    "addParameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un paramètre"])},
    "addRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un enregistrement"])},
    "annulr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulr"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "cerfa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerfa"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effacer"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
    "confirmAndReturnToDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer et revenir au tableau de bord"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecter"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
    "deleteRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suprimmer les données"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
    "editParameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le paramètre"])},
    "editPatient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier l'enregistrement"])},
    "editRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier l'enregistrement"])},
    "editRecordAnalyses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les analyses"])},
    "findRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher un enregistrement"])},
    "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminer"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer"])},
    "invalidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalider"])},
    "invalidateSamples": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalider les échantillons"])},
    "manageANEs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer les ANE"])},
    "manageNCAs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer les NCA"])},
    "mergeDuplicates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fusionner les doublons"])},
    "newRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvel enregistrement"])},
    "precomputeResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculer les résultats"])},
    "prescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prescription"])},
    "receive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recevoir"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
    "seeLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir moins"])},
    "seeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir plus"])},
    "selectFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un fichier"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
    "showSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher le résumé"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soumettre"])},
    "submitChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soumettre des changements"])},
    "tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracking"])},
    "updateTodo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour des tâches à effectuer"])},
    "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider"])}
  },
  "civilities": {
    "BBF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bébé fille"])},
    "BBG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bébé garçon"])},
    "CIQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CIQ"])},
    "EEQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EEQ"])},
    "ENFF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enfant fille"])},
    "ENFG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enfant garçon"])},
    "MME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madame"])},
    "MR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monsieur"])}
  },
  "clinics": {
    "addHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une clinique"])},
    "deleteHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la ou les cliniques"])},
    "disabledHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de la clinique"])},
    "editHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la clinique"])},
    "exportHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clinique(s) d'exportation"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliniques"])}
  },
  "collectors": {
    "addHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un préleveur"])},
    "deleteHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le(s) préleveur(s)"])},
    "disabledHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails du préleveur"])},
    "editHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éditer le préleveur"])},
    "exportHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter le(s) préleveur(s)"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préleveurs"])}
  },
  "countries": {
    "BonaireSintEustatiusAndSaba": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonaire, Saint-Eustache et Saba"])},
    "ChinaHongKongSpecialAdministrativeRegion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chine, région administrative spéciale de Hong Kong"])},
    "ChinaMacaoSpecialAdministrativeRegion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chine, région administrative spéciale de Macao"])},
    "afghanistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afghanistan"])},
    "alandIslands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Îles d'Åland"])},
    "albania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Albanie"])},
    "algeria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algérie"])},
    "americanSamoa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samoa américaines"])},
    "andorra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andorre"])},
    "angola": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angola"])},
    "anguilla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anguilla"])},
    "antarctica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antarctique"])},
    "antiguaAndBarbuda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antigua-et-Barbuda"])},
    "argentina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argentine"])},
    "armenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arménie"])},
    "aruba": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aruba"])},
    "australia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Australie"])},
    "austria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autriche"])},
    "azerbaijan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azerbaïdjan"])},
    "bahamas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahamas"])},
    "bahrain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahreïn"])},
    "bangladesh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bangladesh"])},
    "barbados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barbade"])},
    "belarus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bélarus"])},
    "belgium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgique"])},
    "belize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belize"])},
    "benin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bénin"])},
    "bermuda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bermudes"])},
    "bhutan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bhoutan"])},
    "boliviaPlurinationalStateOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolivie (État plurinational de)"])},
    "bosniaAndHerzegovina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bosnie-Herzégovine"])},
    "botswana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botswana"])},
    "bouvetIsland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Île Bouvet"])},
    "brazil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brésil"])},
    "britishIndianOceanTerritory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Territoire britannique de l'océan Indien"])},
    "britishVirginIslands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Îles Vierges britanniques"])},
    "bruneiDarussalam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brunéi Darussalam"])},
    "bulgaria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulgarie"])},
    "burkinaFaso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burkina Faso"])},
    "burundi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burundi"])},
    "caboVerde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cabo Verde"])},
    "cambodia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambodge"])},
    "cameroon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cameroun"])},
    "canada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canada"])},
    "caymanIslands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Îles Caïmanes"])},
    "centralAfricanRepublic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["République centrafricaine"])},
    "chad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tchad"])},
    "chile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chili"])},
    "china": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chine"])},
    "christmasIsland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Île Christmas"])},
    "cocosKeelingIslands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Îles des Cocos (Keeling)"])},
    "colombia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colombie"])},
    "comoros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comores"])},
    "congo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congo"])},
    "cookIslands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Îles Cook"])},
    "costaRica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costa Rica"])},
    "coteDIvoire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Côte d'Ivoire"])},
    "croatia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croatie"])},
    "cuba": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuba"])},
    "curacao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curaçao"])},
    "cyprus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chypre"])},
    "czechia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tchéquie"])},
    "democraticPeoplesRepublicOfKorea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["République populaire démocratique de Corée"])},
    "democraticRepublicOfTheCongo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["République démocratique du Congo"])},
    "denmark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danemark"])},
    "djibouti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Djibouti"])},
    "dominica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominique"])},
    "dominicanRepublic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["République dominicaine"])},
    "ecuador": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Équateur"])},
    "egypt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Égypte"])},
    "elSalvador": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Salvador"])},
    "equatorialGuinea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinée équatoriale"])},
    "eritrea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Érythrée"])},
    "estonia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estonie"])},
    "eswatini": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eswatini"])},
    "ethiopia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éthiopie"])},
    "falklandIslandsMalvinas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Îles Falkland (Malvinas)"])},
    "faroeIslands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Îles Féroé"])},
    "fiji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fidji"])},
    "finland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finlande"])},
    "france": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["France"])},
    "frenchGuiana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guyane française"])},
    "frenchPolynesia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polynésie française"])},
    "frenchSouthernTerritories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terres australes françaises"])},
    "gabon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gabon"])},
    "gambia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gambie"])},
    "georgia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Géorgie"])},
    "germany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allemagne"])},
    "ghana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghana"])},
    "gibraltar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gibraltar"])},
    "greece": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grèce"])},
    "greenland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groenland"])},
    "grenada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grenade"])},
    "guadeloupe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guadeloupe"])},
    "guam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guam"])},
    "guatemala": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guatemala"])},
    "guernsey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guernesey"])},
    "guinea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinée"])},
    "guineaBissau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinée-Bissau"])},
    "guyana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guyana"])},
    "haiti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haïti"])},
    "heardIslandAndMcDonaldIslands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Île Heard-et-Îles MacDonald"])},
    "holySee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint-Siège"])},
    "honduras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honduras"])},
    "hungary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hongrie"])},
    "iceland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Islande"])},
    "india": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inde"])},
    "indonesia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indonésie"])},
    "iranIslamicRepublicOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iran (République islamique d')"])},
    "iraq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iraq"])},
    "ireland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irlande"])},
    "isleOfMan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Île de Man"])},
    "israel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Israël"])},
    "italy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italie"])},
    "jamaica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jamaïque"])},
    "japan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japon"])},
    "jersey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jersey"])},
    "jordan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jordanie"])},
    "kazakhstan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kazakhstan"])},
    "kenya": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kenya"])},
    "kiribati": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiribati"])},
    "kuwait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koweït"])},
    "kyrgyzstan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirghizistan"])},
    "laoPeoplesDemocraticRepublic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["République démocratique populaire lao"])},
    "latvia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lettonie"])},
    "lebanon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liban"])},
    "lesotho": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesotho"])},
    "liberia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libéria"])},
    "libya": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libye"])},
    "liechtenstein": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liechtenstein"])},
    "lithuania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lituanie"])},
    "luxembourg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxembourg"])},
    "madagascar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madagascar"])},
    "malawi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malawi"])},
    "malaysia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malaisie"])},
    "maldives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maldives"])},
    "mali": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mali"])},
    "malta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malte"])},
    "marshallIslands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Îles Marshall"])},
    "martinique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Martinique"])},
    "mauritania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauritanie"])},
    "mauritius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maurice"])},
    "mayotte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mayotte"])},
    "mexico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mexique"])},
    "micronesiaFederatedStatesOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Micronésie (États fédérés de)"])},
    "monaco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monaco"])},
    "mongolia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mongolie"])},
    "montenegro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monténégro"])},
    "montserrat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montserrat"])},
    "morocco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maroc"])},
    "mozambique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mozambique"])},
    "myanmar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myanmar"])},
    "namibia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namibie"])},
    "nauru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nauru"])},
    "nepal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Népal"])},
    "netherlands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays-Bas"])},
    "newCaledonia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle-Calédonie"])},
    "newZealand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle-Zélande"])},
    "nicaragua": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicaragua"])},
    "niger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niger"])},
    "nigeria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nigéria"])},
    "niue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nioué"])},
    "norfolkIsland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Île Norfolk"])},
    "northernMarianaIslands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Îles Mariannes du Nord"])},
    "norway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norvège"])},
    "oman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oman"])},
    "pakistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakistan"])},
    "palau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palaos"])},
    "panama": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panama"])},
    "papuaNewGuinea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papouasie-Nouvelle-Guinée"])},
    "paraguay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paraguay"])},
    "peru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pérou"])},
    "philippines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Philippines"])},
    "pitcairn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pitcairn"])},
    "poland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pologne"])},
    "portugal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugal"])},
    "puertoRico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porto Rico"])},
    "qatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qatar"])},
    "republicOfKorea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["République de Corée"])},
    "republicOfMoldova": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["République de Moldova"])},
    "reunion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réunion"])},
    "romania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roumanie"])},
    "russianFederation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fédération de Russie"])},
    "rwanda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rwanda"])},
    "saintBarthelemy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint-Barthélemy"])},
    "saintHelena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sainte-Hélène"])},
    "saintKittsAndNevis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint-Kitts-et-Nevis"])},
    "saintLucia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sainte-Lucie"])},
    "saintMartinFrenchPart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint-Martin (partie française)"])},
    "saintPierreAndMiquelon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint-Pierre-et-Miquelon"])},
    "saintVincentAndTheGrenadines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint-Vincent-et-les Grenadines"])},
    "samoa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samoa"])},
    "sanMarino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint-Marin"])},
    "saoTomeAndPrincipe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sao Tomé-et-Principe"])},
    "saudiArabia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabie saoudite"])},
    "senegal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sénégal"])},
    "serbia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbie"])},
    "seychelles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seychelles"])},
    "sierraLeone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sierra Leone"])},
    "singapore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singapour"])},
    "sintMaartenDutchPart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint-Martin (partie néerlandaise)"])},
    "slovakia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovaquie"])},
    "slovenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovénie"])},
    "solomonIslands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Îles Salomon"])},
    "somalia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somalie"])},
    "southAfrica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afrique du Sud"])},
    "southGeorgiaAndTheSouthSandwichIslands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Géorgie du Sud-et-les Îles Sandwich du Sud"])},
    "southSudan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soudan du Sud"])},
    "spain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espagne"])},
    "sriLanka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sri Lanka"])},
    "stateOfPalestine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["État de Palestine"])},
    "sudan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soudan"])},
    "suriname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suriname"])},
    "svalbardAndJanMayenIslands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Îles Svalbard-et-Jan Mayen"])},
    "sweden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suède"])},
    "switzerland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suisse"])},
    "syrianArabRepublic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["République arabe syrienne"])},
    "taiwan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taïwan"])},
    "tajikistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tadjikistan"])},
    "thailand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thaïlande"])},
    "theFormerYugoslavRepublicOfMacedonia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ex-République yougoslave de Macédoine"])},
    "timorLeste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timor-Leste"])},
    "togo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Togo"])},
    "tokelau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokélaou"])},
    "tonga": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tonga"])},
    "trinidadAndTobago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trinité-et-Tobago"])},
    "tunisia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunisie"])},
    "turkey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turquie"])},
    "turkmenistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkménistan"])},
    "turksAndCaicosIslands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Îles Turques-et-Caïques"])},
    "tuvalu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuvalu"])},
    "uganda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouganda"])},
    "ukraine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukraine"])},
    "unitedArabEmirates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Émirats arabes unis"])},
    "unitedKingdomOfGreatBritainAndNorthernIreland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Royaume-Uni de Grande-Bretagne et d'Irlande du Nord"])},
    "unitedRepublicOfTanzania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["République-Unie de Tanzanie"])},
    "unitedStatesMinorOutlyingIslands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Îles mineures éloignées des États-Unis"])},
    "unitedStatesOfAmerica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["États-Unis d'Amérique"])},
    "unitedStatesVirginIslands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Îles Vierges américaines"])},
    "uruguay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uruguay"])},
    "uzbekistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouzbékistan"])},
    "vanuatu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vanuatu"])},
    "venezuelaBolivarianRepublicOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venezuela (République bolivarienne du)"])},
    "vietNam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viet Nam"])},
    "wallisAndFutunaIslands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Îles Wallis-et-Futuna"])},
    "westernSahara": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sahara occidental"])},
    "yemen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yémen"])},
    "zambia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zambie"])},
    "zimbabwe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zimbabwe"])}
  },
  "dashboard": {
    "notifications": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avis"])}
    },
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de bord"])},
    "quarterlyStats": {
      "annulr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulé et remplacé"])},
      "numExternalRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrements externes"])},
      "numRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrements ajoutés"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiques trimestrielles"])}
    },
    "quickAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès rapide"])},
    "seeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir"])},
    "stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiques"])},
    "summary1": {
      "stat1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours"])},
      "stat2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garé"])},
      "stat3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyé"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aujourd'hui"])}
    },
    "summary2": {
      "stat1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours d'analyse"])},
      "stat2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pré-inscrit"])},
      "stat3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyé"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Types d'analyse"])}
    },
    "summary3": {
      "stat1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours d'analyse"])},
      "stat2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pré-inscrit"])},
      "stat3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyé"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CL / TES / CLB"])}
    },
    "summaryOfTheDay": {
      "stat1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patients ajoutés"])},
      "stat2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échantillons traités"])},
      "stat3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses terminées"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voici ce qui s'est passé depuis ce matin :"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résumé de la journée"])}
    },
    "tasksInProgress": {
      "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossiers en cours"])},
      "sentExternally": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossiers Envoi Externe"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tâches en cours"])},
      "toFax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossiers à faxer"])},
      "toInsuranceReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapports d'assurance à traiter"])},
      "toOnlineReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapports en ligne à traiter"])},
      "toPaperReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapports papier à traiter"])},
      "toPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossiers a téléphoner"])},
      "toPhysicianReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapports du médecin à traiter"])},
      "toPost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossiers à poster"])},
      "toValidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossiers à valider"])},
      "urgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossiers urgents"])},
      "validatedToday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossiers validés aujourd'hui"])},
      "withANE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossiers avec ANEs"])},
      "withLbloc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossiers avec LBLOC"])},
      "withNCA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossiers avec NCAs"])},
      "withNCT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossiers avec NCTs"])}
    }
  },
  "dataManagement": {
    "allInternalNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes internes de tous les patients"])},
    "confirmDeleting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez confirmer que vous allez supprimer le(s) patient(s) ci-dessus qui ont été surlignés en rouge. "])},
    "confirmKeeping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez confirmer que vous allez conserver tous les enregistrements ci-dessus. "])},
    "deleting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppression"])},
    "duplicateModals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résoudre les doublons"])},
    "internalNotesToKeep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes internes à conserver"])},
    "keeping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En gardant"])},
    "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clé"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion de données"])},
    "searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de recherche..."])},
    "selecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélection"])},
    "submitWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous choisissez un patient à supprimer, vous devez également sélectionner un patient qui sera le remplaçant avant de soumettre"])}
  },
  "ems": {
    "accessTokenExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le jeton d'accès a expiré"])},
    "adminNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'administrateur n'existe pas"])},
    "alreadyVerified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déjà vérifié"])},
    "analysisIncomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les données d'analyse dans la base de données sont incomplètes"])},
    "analysisNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'analyse n'existe pas"])},
    "authNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La session d'authentification n'existe pas"])},
    "backupNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La sauvegarde n'existe pas"])},
    "cannotChangeSelectedAnalysesStructure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de modifier la structure d'analyse sélectionnée"])},
    "cannotDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de supprimer ce type de fichier"])},
    "cannotDowngradeStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de rétrograder le statut"])},
    "cannotEditLock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de modifier les enregistrements importés des versions précédentes de Ninja"])},
    "cannotOrderHiddenAnalyses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de commander des analyses masquées"])},
    "cannotUpdateRightsOfExternalUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de changer les droits des utilisateurs externes"])},
    "cannotUpdateVerifiedEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de changer l'e-mail du compte vérifié"])},
    "clinicNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La clinique n'existe pas"])},
    "clinicUserOverreached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les utilisateurs de la clinique ne peuvent accéder qu'à leurs actifs"])},
    "codeAlreadyInUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code est déjà utilisé"])},
    "collectorNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le préleveur n'existe pas"])},
    "csvExportFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de l'exportation CSV"])},
    "duplicateNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le doublon n'existe pas"])},
    "emailAlreadyInUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cet email est déjà utilisé"])},
    "emailPendingVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail en attente de vérification ou en cours d'utilisation"])},
    "familyNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La famille n'existe pas"])},
    "formatterNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fonction de formatage n'existe pas"])},
    "incorrectCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiants incorrects"])},
    "incorrectEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'e-mail est incorrect ou invalide"])},
    "incorrectOtp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OT incorrect"])},
    "incorrectPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le numéro de portable est incorrect ou invalide"])},
    "incorrectType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type incorrect pour un ou plusieurs paramètres"])},
    "insInfoNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de récupérer les informations d'INS"])},
    "insStatusNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de vérifier l'état INS"])},
    "insufficientAdminRights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez des droits d'administrateur insuffisants"])},
    "invalidAccessSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres invalides"])},
    "invalidAccessToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeton d'accès non valide"])},
    "invalidApiToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeton invalide"])},
    "invalidBounds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètre configuré avec des limites non valides dans BE"])},
    "invalidClinicCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code de la clinique est invalide"])},
    "invalidListOfPatientsToDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste invalide de patients à supprimer"])},
    "invalidName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nom n'est pas valide"])},
    "invalidOperation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tentative d'opération invalide"])},
    "invalidOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option non valide sélectionnée"])},
    "invalidRecordStatusForInvalidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'état de l'enregistrement empêche l'invalidation"])},
    "invalidRecordStatusForUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'enregistrement n'est pas prêt à être mis à jour"])},
    "invalidRecordStatusForValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'enregistrement n'est pas prêt pour la validation"])},
    "invalidRefreshToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeton d'actualisation non valide"])},
    "laboratoryNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le laboratoire n'existe pas"])},
    "machineNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La machine n'existe pas"])},
    "matrixNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La matrice n'existe pas"])},
    "missingData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données manquantes dans la demande de base de données"])},
    "missingVerifiedPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vérifier votre numéro de portable"])},
    "mustCloseNct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'échantillon a un NCT actif"])},
    "nabmNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NABM n'existe pas"])},
    "nameTaken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nom de l'actif est pris"])},
    "ncaNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NCA n'existe pas"])},
    "noAdminRights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez aucun droit d'administrateur"])},
    "noTablesToBackup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune table à sauvegarder"])},
    "optionsPresetNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le préréglage d'options n'existe pas"])},
    "otpExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP a expiré"])},
    "otpNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP n'a pas été demandé"])},
    "outOfBounds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le paramètre est hors limites"])},
    "parameterIncomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les données de paramètres dans la base de données sont incomplètes"])},
    "parameterNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le paramètre n'existe pas"])},
    "patientNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le malade n'existe pas"])},
    "phoneAlreadyInUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le téléphone est déjà utilisé"])},
    "prescriberNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le prescripteur n'existe pas"])},
    "recordAlreadyScanned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déjà scanné"])},
    "recordIdCreationFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La création de l'enregistrement a échoué"])},
    "recordIncomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les données d'enregistrement dans la base de données sont incomplètes"])},
    "recordNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'enregistrement n'existe pas"])},
    "reportsApiReqFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de la demande à l'API des rapports"])},
    "resetExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialisation expirée"])},
    "resetNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'entrée de réinitialisation n'existe pas"])},
    "routeConfigNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'itinéraire n'existe pas en BE"])},
    "sampleIncomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les exemples de données dans la base de données sont incomplets"])},
    "sampleNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'échantillon n'existe pas"])},
    "sampleTypeNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le type d'échantillon n'existe pas"])},
    "smartApiReqFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de la demande à l'API intelligente"])},
    "templateNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le modèle n'existe pas"])},
    "typeCheckFunNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fonction de vérification de type n'existe pas"])},
    "userNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'utilisateur n'existe pas"])},
    "verificationExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le lien de vérification a expiré"])},
    "verificationNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'élément de vérification n'existe pas"])}
  },
  "export": {
    "addHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une exportation"])},
    "deleteHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l'exportation"])},
    "disabledHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter les détails"])},
    "editHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier l'exportation"])},
    "exportHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter des enregistrements"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter"])}
  },
  "families": {
    "addHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une famille"])},
    "deleteHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la famille"])},
    "disabledHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de famille"])},
    "editHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la famille"])},
    "exportHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter les familles"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Familles"])}
  },
  "filterOptions": {
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appliquer"])},
    "byClinic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer par clinique"])},
    "byCollector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer par préleveur"])},
    "byFamily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer par famille"])},
    "byMachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer par machine"])},
    "byMatrix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer par matrice"])},
    "byPrescriber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer par prescripteur"])},
    "createdFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créé de"])},
    "createdTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à"])},
    "hiddenAnalyses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses internes"])},
    "hiddenInReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caché dans le rapport"])},
    "visibleAnalyses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses publiques"])},
    "visibleInReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visible dans le rapport"])}
  },
  "formFields": {
    "updateComments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour renseignement demande"])},
    "notAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas disponible"])},
    "linkedSample": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échantillon lié"])},
    "accessAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès administrateur"])},
    "addANE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un nouvel ANE"])},
    "additionalSamples": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échantillons supplémentaires"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Âge (Années)"])},
    "analysesNames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noms des analyses"])},
    "analysisAllocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répartition de l'échantillon (%)"])},
    "analysisCustomCost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coût supplémentaire/personnalisé (€)"])},
    "analysisName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'analyse"])},
    "aneAnalysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANE analyse"])},
    "aneCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANE code"])},
    "basicDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de base"])},
    "billingUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur de facturation"])},
    "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civilité"])},
    "clinic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clinique"])},
    "clinicalHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations clinico-biologiques"])},
    "collectorCreateNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un nouveau préleveur"])},
    "collector_billed_by_lab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturé par le labo"])},
    "collector_billing_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro d'identification facturation"])},
    "collector_call_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code d'appel"])},
    "collector_conventional_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code conventionnel"])},
    "collector_ged_doc_diff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diffusion Document GED"])},
    "collector_ik_zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zone IK"])},
    "collector_internal_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code interne"])},
    "collector_obsolete_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obsolète à partir du"])},
    "collector_practice_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condition d'exercice"])},
    "collector_pricing_zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zone tarification"])},
    "collector_qualification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualification"])},
    "collector_structure_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de structure"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmez le mot de passe"])},
    "confirmSamplingTimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer l'heure d'échantillonnage"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
    "countryCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préfix du pays"])},
    "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de création"])},
    "dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dates"])},
    "dob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "externalSamples": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprend des échantillons externes ?"])},
    "externalUserType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de compte"])},
    "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAUX"])},
    "familyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la Famille"])},
    "familyRank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priorité dans le rapport"])},
    "familyReportMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message du rapport"])},
    "family_is_envoi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des analyses comme externes dans le rapport ?"])},
    "faxNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de fax"])},
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Femme"])},
    "filterAndSort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer et Trier"])},
    "filterBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer par"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
    "getIns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupérer INS"])},
    "governmentINS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identité Nationale de Santé Intégré (INS)"])},
    "governmentINSType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d'INH"])},
    "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauteur (cm)"])},
    "hideInReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masquer dans le rapport"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDENTIFIANT"])},
    "insurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mutuelle du patient"])},
    "insuranceAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de convention"])},
    "internal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interne"])},
    "internalNotesPatient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renseignement patient"])},
    "internalNotesRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renseignement demande"])},
    "invalidationAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrateur d'invalidation"])},
    "isAccredited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accrédité ?"])},
    "iso2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISO 2"])},
    "iso3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISO 3"])},
    "laboratory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laboratoire"])},
    "landline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone fixe"])},
    "lastUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière mise à jour"])},
    "lbloc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LBloc"])},
    "lblocDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description du LBloc"])},
    "lblocFlag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LBloc ?"])},
    "machineCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de la machine"])},
    "machineLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emplacement de la machine"])},
    "machineName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la machine"])},
    "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homme"])},
    "matrixName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrice biologique"])},
    "medicalUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur médical"])},
    "nabm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NABM"])},
    "nabmActesReserves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actes se réserve"])},
    "nabmAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accord"])},
    "nabmBioInitiative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initiative bio"])},
    "nabmBloodTest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test sanguin"])},
    "nabmChapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapitre"])},
    "nabmCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "nabmCoefB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coef B"])},
    "nabmConflictsExplanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des conflits entre les codes NABM ont été trouvés. Pour les conflits entre différentes analyses, veuillez revenir à la création de l'enregistrement et supprimer les analyses conflictuelles. Pour les conflits entre la même analyse, cela signifie que l'analyse a été mal configurée au moment de la création. Pour résoudre ce problème, veuillez modifier l'analyse conflictuelle dans la page des analyses."])},
    "nabmContingency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contingence"])},
    "nabmIncompatibleCodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codes incompatibles"])},
    "nabmLastEeetDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière date EEET"])},
    "nabmMaxNumCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code numérique maximum"])},
    "nabmMedIndicRef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réf. méd."])},
    "nabmRefund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remboursement"])},
    "nabmRmo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RMO"])},
    "nabmSpecRuleNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de règle de spécification"])},
    "nabmSubChapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sous-chapitre"])},
    "nabmWording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulation"])},
    "nabms100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NABMs 100"])},
    "nabms60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NABMs 60"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "nameAtBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de naissance"])},
    "ncaCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "ncaDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "ncaDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails du NCA"])},
    "ncaResolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résolu ?"])},
    "ncaSolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution"])},
    "nct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NCT"])},
    "nctCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code du NCT"])},
    "nctDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description du NCT"])},
    "nctResolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NCT actif ?"])},
    "nctSolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution du NCT"])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe"])},
    "newValues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelles valeurs"])},
    "newborn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau-née"])},
    "noANEs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune ANE n'a été levée pour cet enregistrement"])},
    "noNCAs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune ANC n'a été levée pour cet enregistrement"])},
    "numberToPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro à téléphoner"])},
    "oldValues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anciennes valeurs"])},
    "otherPeopleToPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre(s) personne(s) à appeler"])},
    "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe à usage unique"])},
    "outOfRangeWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce résultat est hors plage. Les propriétés de plage valides pour ce patient sont :"])},
    "parameterDecimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décimales dans les résultats"])},
    "parameterFormula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formule"])},
    "parameterFormulaUnits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unités après calcul"])},
    "parameterHasFormula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le paramètre a une formule ?"])},
    "parameterLowerDetectionLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite inférieure de détection"])},
    "parameterMatrix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrice"])},
    "parameterMatrixRangeComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
    "parameterMatrixRangeDelta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delta"])},
    "parameterMatrixRangeFemale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gamme pour les femelles adultes"])},
    "parameterMatrixRangeFormulaComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire après la conversion"])},
    "parameterMatrixRangeMale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gamme pour les mâles adultes"])},
    "parameterMatrixRangeMax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max"])},
    "parameterMatrixRangeMin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min"])},
    "parameterMatrixRangeNewborn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gamme pour les nouveau-nés"])},
    "parameterMatrixRangePregnant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gamme pour les femmes enceintes"])},
    "parameterMatrixRangeUseDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliser uniquement la plage par défaut"])},
    "parameterMatrixRange_0_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gamme pour les 0 à 1 ans"])},
    "parameterMatrixRange_1_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gamme pour les 1 à 6 ans"])},
    "parameterMatrixRange_6_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gamme pour les 6 à 15 ans"])},
    "parameterMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méthode"])},
    "parameterName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nom du paramètre"])},
    "parameterOpenFormulaModal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrir l'interface de formule"])},
    "parameterRank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rang"])},
    "parameterShowConversionReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avant et après dans le rapport?"])},
    "parameterTechSheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiche technique"])},
    "parameterUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unités"])},
    "parameterUpperDetectionLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite supérieure de détection"])},
    "parameterUrineEch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échantillon d'urine"])},
    "parameterValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur du parametre"])},
    "parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Molécules"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
    "passwordNoMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mots de passe ne correspondent pas"])},
    "passwordReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser le mot de passe"])},
    "patientConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les états du patient sont :"])},
    "patientCreateNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un nouveau patient"])},
    "patientInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations patient"])},
    "patientNameSurname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche par nom, prénom, sécurité sociale ou ID de dossier du patient"])},
    "patientSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher un patient"])},
    "patient_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID externe"])},
    "patient_insurance_code1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mutuelle AMC"])},
    "patient_insurance_code2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mutuelle télétransmission"])},
    "patient_insurance_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mutuelle de"])},
    "patient_insurance_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mutuelle à"])},
    "patient_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clinique"])},
    "patient_posology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posologie"])},
    "patient_treatment_last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date et heure de dernière prise"])},
    "peopleToPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne(s) à appeler"])},
    "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
    "placeOfBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu de naissance (code COG FR)"])},
    "postCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])},
    "pregnant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enceinte"])},
    "prescriber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prescripteur"])},
    "prescriberAdeli": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro ADELI"])},
    "prescriberCreateNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un nouveau prescripteur"])},
    "prescriberSpecialtyCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code spécialité médicale"])},
    "prescriptionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de l'ordonnance"])},
    "public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public"])},
    "quickSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélection rapide"])},
    "quoting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Citation"])},
    "recordAnalyses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses sélectionnées"])},
    "recordAnnulrReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explication de l'annulation"])},
    "recordDelaiActualTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delai l'heure réelle"])},
    "recordDelaiCause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cause du Delai"])},
    "recordDelaiExpectedTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Délai prévu"])},
    "recordDelaiFlag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delai ?"])},
    "recordDelaiImpact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impact du Delai sur le patient"])},
    "recordDerogDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description DEROG"])},
    "recordDerogFlag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DEROG ?"])},
    "recordFaxNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de fax"])},
    "recordInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer des informations"])},
    "recordInterpretation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interprétation de l'enregistrement"])},
    "recordIsUrgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urgent ?"])},
    "recordMustFax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A faxer"])},
    "recordMustInsuranceReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilan assurance"])},
    "recordMustOccupationalPhysician": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Médecine du travail"])},
    "recordMustOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CR internet"])},
    "recordMustPaper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CR papier"])},
    "recordMustPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A téléphoner"])},
    "recordMustPost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A poster"])},
    "recordNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro d'enregistrement"])},
    "recordNumbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéros d'enregistrement"])},
    "recordOralFlag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordonnance orale ?"])},
    "recordPendingFax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente de la faxation des résultats"])},
    "recordPendingInsuranceReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport d'assurance en attente"])},
    "recordPendingOccupationalPhysician": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport de médecin en attente"])},
    "recordPendingOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport en ligne en attente"])},
    "recordPendingPaper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport papier en attente"])},
    "recordPendingPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appel téléphonique en attente"])},
    "recordPendingPost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente de poste des résultats"])},
    "recordUhExpectedTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heure UH attendue"])},
    "recordUhFlag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UH ?"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer"])},
    "requiresSamplingTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps d'échantillonnage requis ?"])},
    "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultat"])},
    "rppsNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro RPPS"])},
    "sOid": {
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testing INS-NIR assignment authority"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demonstration INS-NIR assignment authority"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INS-NIR assignment authority"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INS-NIA assignment authority"])}
    },
    "sampleCollectedExternally": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échantillons prélevés à l'extérieur ?"])},
    "sampleCollectionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date et heure de prélèvement"])},
    "sampleCollector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préleveur"])},
    "sampleInjected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Injecté"])},
    "sampleInterpretation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interprétation des échantillons"])},
    "sampleNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro d'échantillon"])},
    "samplePrepared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préparé"])},
    "sampleRemoved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimé"])},
    "sampleReportSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport envoyé"])},
    "sampleType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d'échantillon"])},
    "sampleTypeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description de l'échantillon"])},
    "sampleTypeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'échantillon"])},
    "sampleValidated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validé"])},
    "samplingTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps de prélèvement"])},
    "sex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sexe"])},
    "socialSecurity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de sécurité sociale"])},
    "sortBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trier par"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
    "summaryOfChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résumé des modifications"])},
    "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre"])},
    "titleDoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dr"])},
    "titleInf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inf"])},
    "titleMed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Med"])},
    "titleMiss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miss"])},
    "titleMlle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mlle"])},
    "titleMme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mme"])},
    "titleMr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mr"])},
    "titleMrs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mrs"])},
    "titleMs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ms"])},
    "todoList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tâches à effectuer"])},
    "treatingPractitioner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Médecin traitaint"])},
    "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VRAI"])},
    "unresolvedNCA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NCA non résolus"])},
    "uploadBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargez votre fichier .csv ici. "])},
    "userType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d'utilisateur"])},
    "validationAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrateur de validation"])},
    "visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibilité"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poids (kg)"])}
  },
  "help": {
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez-nous"])},
    "contactUsInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement, contactez les administrateurs"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "onlineTutorials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutoriels en ligne"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aider"])},
    "tutorialInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besoin d'aide? "])}
  },
  "import": {
    "THC-COOH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["THC-COOH"])},
    "acheminement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expéditeur"])},
    "chlamydia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chlamydia"])},
    "confirmImport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer les résultats des échantillons"])},
    "ecbu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECBU"])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un fichier"])},
    "fileType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de fichier"])},
    "groupage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GROUPE SANGUIN"])},
    "icpms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ICPMS"])},
    "moleculesFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Molécules trouvées"])},
    "mstPcr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MST"])},
    "mycoplasma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mycoplasme"])},
    "neisseria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neisseria"])},
    "noFileSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun fichier sélectionné"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer"])},
    "parametersFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres trouvés"])},
    "pcrMst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MST"])},
    "pcrResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RÉSULTATS PCR COVID-19"])},
    "pcrRhino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENREGISTREMENTS PCR RHINO-PHARYNGIENS"])},
    "pcrSaliva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENREGISTREMENTS PCR SALIVAIRE"])},
    "qualitative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QUALITATIF"])},
    "qualitativeLcQtof": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LCQTOF QUALITATIVE"])},
    "quantitative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QUANTITATIF"])},
    "rai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAI"])},
    "thcD3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["THC-d3 (ISTD)"])}
  },
  "info": {
    "accreditations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accréditations"])},
    "ninja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LIMS ninja"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
    "paragraph1Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Présentation de Ninja LIMS, le logiciel de gestion de laboratoire développé par Zedsoft."])},
    "paragraph2Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avec Ninja LIMS, vous pouvez facilement gérer et automatiser les opérations de votre laboratoire. "])},
    "paragraph3Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous fournissons des modules et des fonctionnalités puissants pour vous aider à optimiser l'efficacité de votre laboratoire. "])},
    "paragraph4Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De plus, Ninja LIMS est hautement sécurisé, garantissant que vos données sont toujours sûres et sécurisées. "])},
    "paragraph5Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N'attendez plus - procurez-vous Ninja LIMS et commencez à optimiser la gestion de votre laboratoire dès aujourd'hui !"])}
  },
  "login": {
    "backToConnection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour connexion"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié"])},
    "forgotPasswordPrompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir l'adresse e-mail que vous utilisez pour vous connecter. Nous enverrons un e-mail de récupération de mot de passe à cette adresse."])},
    "forgotPasswordQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié?"])},
    "otpPrompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons envoyé un mot de passe à usage unique sur votre mobile. Veuillez le saisir ici pour vous connecter."])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonjour ! Votre assistant de laboratoire basé sur le cloud vous attend. Veuillez vous connecter à votre compte."])},
    "productOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un produit par"])}
  },
  "machines": {
    "addHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un appareil"])},
    "deleteHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer des machines"])},
    "disabledHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de l'appareil"])},
    "editHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier l'ordinateur"])},
    "exportHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter des machines"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machines"])}
  },
  "matrices": {
    "addHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une matrice"])},
    "deleteHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la matrice"])},
    "disabledHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de la matrice"])},
    "editHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la matrice"])},
    "exportHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter les matrices"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrices"])}
  },
  "misc": {
    "accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comptes"])},
    "addRecordSubtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un nouveau dossier/patient..."])},
    "adminPermissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AUTORISATIONS"])},
    "adminSpecialRights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DROITS SPÉCIAUX"])},
    "compteSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres utilisateur"])},
    "copySuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texte copié dans le presse-papiers"])},
    "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données"])},
    "dataSubText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer les dossiers ou les doublons..."])},
    "defaultFormula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formule par défaut"])},
    "defaultResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats par défaut"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lire, modifier et supprimer"])},
    "deleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimé avec succès"])},
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anglais"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ERREUR"])},
    "femaleFormula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formule féminine"])},
    "femaleResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats féminins"])},
    "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminer"])},
    "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formule"])},
    "french": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français"])},
    "genderSpecificFormula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formules spécifiques au genre"])},
    "inactivityLogout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez été déconnecté pour inactivité"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se déconnecter"])},
    "maleFormula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formule masculine"])},
    "maleResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats masculins"])},
    "noPermissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pas de permissions"])},
    "patient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient"])},
    "patients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les patients"])},
    "presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Présentation"])},
    "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lire"])},
    "recordsDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrements supprimés"])},
    "recordsReceived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossiers reçus"])},
    "requiredAnalyses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SÉLECTIONNER L'ANALYSE REQUISE"])},
    "returnToDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous n'avons pas pu trouver la page demandée. "])},
    "returnToDashboardButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour au tableau de bord"])},
    "samplingGuide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuel de prélèvement"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramétrage"])},
    "settingsSubText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les détails de l'utilisateur..."])},
    "testFormula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formule d'essai"])},
    "themeToggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer de thème"])},
    "uiOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options d'interface"])},
    "verificationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez sur le bouton pour revenir au tableau de bord"])},
    "verificationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification terminée"])},
    "whatToDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Que souhaitez-vous faire ?"])},
    "write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lire et éditer"])}
  },
  "nabms": {
    "addHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter NABM"])},
    "deleteHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer NABM"])},
    "disabledHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails NABM"])},
    "editHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier NABM"])},
    "exportHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter les NABM"])},
    "nonRefundable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NABM non remboursables"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NABM"])},
    "refundable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NABM remboursables"])}
  },
  "ncas": {
    "nca1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renseignements administratif manquant ou erroné"])},
    "nca2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiche de transmission externe absent ou incomplet"])},
    "nca3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur d'identité"])},
    "nca4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oubi d'analyse"])},
    "nca5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identité discordante"])},
    "nca6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur d'analyse"])},
    "nca7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordonnance illisible"])},
    "nca8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oubli ou erreur de transmission"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])}
  },
  "ncts": {
    "nct1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Echantillon manquant"])},
    "nct2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prélèvement insuffisant"])},
    "nct3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Echantillon non conforme"])},
    "nct4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tube et contenant périmé"])},
    "nct5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Délais pré-analytique dépassé"])},
    "nct6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Echantillon mal/non identifié"])},
    "nct7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renseignement clinique"])},
    "nct8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Délai de rendu de résultat"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])}
  },
  "notifications": {
    "frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fréquence :"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avis"])},
    "push": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pousser"])}
  },
  "parameters": {
    "addHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un paramètre"])},
    "deleteHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le(s) paramètre(s)"])},
    "disabledHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails des paramètres"])},
    "editHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le paramètre"])},
    "exportHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètre(s) d'exportation"])},
    "generalParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres généraux"])},
    "moleculeMedium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milieu de la molécule"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])}
  },
  "patientHistory": {
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antécédents du patient"])}
  },
  "peopleToPhone": {
    "doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Praticien traitant"])},
    "healthEstablishment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Établissement de santé"])},
    "nurse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infirmière"])},
    "patient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient"])},
    "prescriber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prescripteur"])}
  },
  "prescriberSpecialtyCodes": {
    "code0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non spécifié"])},
    "code1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Médecine générale"])},
    "code10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neurochirurgie"])},
    "code11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oto-rhino-laryngologie"])},
    "code12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pédiatre"])},
    "code13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pneumologie"])},
    "code14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rhumatologie"])},
    "code15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ophtalmologie"])},
    "code16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urologie"])},
    "code17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuropsychiatrie"])},
    "code18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stomatologie"])},
    "code19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chirurgien Dentiste"])},
    "code2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anesthésie"])},
    "code20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réanimation médicale"])},
    "code21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sage-femme"])},
    "code22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spécialiste en médecine générale avec diplôme"])},
    "code23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spécialiste en médecine générale reconnu par l'ordre"])},
    "code24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infirmier"])},
    "code26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masseur-kinésithérapeute"])},
    "code27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pédicure-podologue"])},
    "code28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orthophoniste"])},
    "code29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orthoptiste"])},
    "code3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cardiologie"])},
    "code30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laboratoire d'analyses médicales"])},
    "code31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rééducation Réadaptation fonctionnelle"])},
    "code32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neurologie"])},
    "code33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Psychiatrie"])},
    "code34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gériatrie"])},
    "code35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Néphrologie"])},
    "code36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chirurgien dentiste spécialité O.D.F"])},
    "code37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anatomo-cyto-pathologie"])},
    "code38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Médecin biologiste"])},
    "code39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laboratoire d'analyses de biologie médicale polyvalent"])},
    "code4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chirurgie générale"])},
    "code40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laboratoire d'analyses de biologie et de cytologie pathologique"])},
    "code41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chirurgie orthopédique"])},
    "code42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endocrinologie et métabolisme"])},
    "code43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chirurgie infantile"])},
    "code44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chirurgie Maxillo-faciale"])},
    "code45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chirurgie Maxillo-faciale et Stomatologie"])},
    "code46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chirurgie Plastique et esthétique"])},
    "code47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chirurgie Thoracique et Cardiologie Vasculaire"])},
    "code48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chirurgie Vasculaire"])},
    "code49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chirurgie Viscérale et Digestive"])},
    "code5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dermatologie-vénérologie"])},
    "code50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pharmacien"])},
    "code51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pharmacien mutualiste"])},
    "code53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chirurgien Dentiste CO"])},
    "code54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chirurgien Dentiste MBD"])},
    "code6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electroradiologie"])},
    "code60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournisseur société"])},
    "code61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournisseur artisan"])},
    "code62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournisseur association"])},
    "code63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orthopédiste-orthésiste"])},
    "code64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opticien"])},
    "code65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audioprothésiste"])},
    "code66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oculariste Epithésiste"])},
    "code67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podo-orthésistes"])},
    "code68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orthoprothésistes"])},
    "code69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chirurgie orale"])},
    "code7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gynécologie-obstétrique"])},
    "code70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gynécologie Médicale"])},
    "code71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hématologie"])},
    "code72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Médecine Nucléaire"])},
    "code73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oncologie Médicale"])},
    "code74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oncologie Radiothérapique"])},
    "code75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Psychiatrie Infantile"])},
    "code76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radiothérapie"])},
    "code77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obstétrique"])},
    "code78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Génétique médicale"])},
    "code79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gynécologie obstétrique et gynécologie médicale"])},
    "code8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastro-enthérologie"])},
    "code80": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Santé publique et médecine sociale"])},
    "code9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Médecine interne"])}
  },
  "prescribers": {
    "addHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un prescripteur"])},
    "deleteHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer un prescripteur"])},
    "disabledHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordonnées du prescripteur"])},
    "editHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le prescripteur"])},
    "exportHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter les prescripteurs"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prescripteurs"])}
  },
  "rapidSelection": {
    "alcohol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alcool"])},
    "blood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sang"])},
    "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complet"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un autre choix"])},
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport"])},
    "rtPCR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RT-PCR"])}
  },
  "records": {
    "addHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un enregistrement"])},
    "addedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ajouté avec succès"])},
    "availableRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrements disponibles"])},
    "deleteHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer des enregistrements"])},
    "disabledHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de l'enregistrement"])},
    "editHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier l'enregistrement"])},
    "exportHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter les enregistrements"])},
    "hasMultiprescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plusieurs prescriptions?"])},
    "linkedRecordsInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez sur un enregistrement pour voir les échantillons qui lui sont associés."])},
    "linkedSamplesInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce sont les échantillons et analyses associés à l'enregistrement sélectionné. Cliquez sur n'importe quelle analyse pour la lier à l'enregistrement actuel."])},
    "multiPrescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'enregistrement contient plusieurs prescriptions"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossiers"])},
    "patientDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails du patient"])},
    "previewRecordTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu du dossier"])},
    "recordAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrement ajouté"])},
    "recordLinkedAnalyses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses liés à l'enregistrement"])},
    "recordReceived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrement reçu avec succès"])},
    "samplesToBeLinked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce sont les échantillons et analyses qui seront liés au nouvel enregistrement."])},
    "selectLinkedRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner les enregistrements liés"])},
    "sentExternally": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Envoi externe)"])},
    "status0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pré-enregistrée"])},
    "status1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistré"])},
    "status2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitement"])},
    "status3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validation en attente"])},
    "status4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente de soumission"])},
    "status5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyé"])},
    "toPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payer"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "totalQuote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devis total"])}
  },
  "sample_types": {
    "addHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un type d'échantillon"])},
    "deleteHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer les types d'échantillons"])},
    "disabledHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails du type d'échantillon"])},
    "editHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le type d'échantillon"])},
    "exportHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter le(s) type(s) d'échantillons"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Types d'échantillons"])}
  },
  "samples": {
    "sample_injected_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échantillon injecté par"])},
    "sample_prepared_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échantillon préparé par"])},
    "sample_removed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échantillon supprimé par"])},
    "sample_validated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échantillon validé par"])}
  },
  "scanner": {
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scanner"])}
  },
  "security": {
    "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer le mot de passe"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmez le mot de passe"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié?"])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe"])},
    "otpField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer mot de passe à usage unique via SMS"])},
    "otpText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe à usage unique"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sécurité"])},
    "pcField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer ProSante Connect"])},
    "pcText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ProSante Connect"])},
    "phoneNumberRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de portable requis"])},
    "previousPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe précédent"])}
  },
  "sortOptions": {
    "asc": {
      "NABM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NABM (ASC)"])},
      "adeli": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adéli (ASC)"])},
      "chapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapitre (ASC)"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code (ASC)"])},
      "coefB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coef B (ASC)"])},
      "creationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière mise à jour (ASC)"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désignation (ASC)"])},
      "dob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance (ASC)"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriel (ASC)"])},
      "family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Famille (ASC)"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom (ASC)"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID (ASC)"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emplacement (ASC)"])},
      "matrix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrice (ASC)"])},
      "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méthode (ASC)"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom (ASC)"])},
      "nonCompliance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-conformité (ASC)"])},
      "observations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Observations (ASC)"])},
      "rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rang (ASC)"])},
      "recordStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["État de l'enregistrement (ASC)"])},
      "subChapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sous-chapitre (ASC)"])},
      "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom (ASC)"])},
      "wording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libellé (ASC)"])}
    },
    "desc": {
      "NABM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NABM (DESC)"])},
      "adeli": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adéli (DESC)"])},
      "chapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapitre (DESC)"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code (DESC)"])},
      "coefB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coef B (DESC)"])},
      "creationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière mise à jour (DESC)"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descriptif (DESC)"])},
      "dob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance (DESC)"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriel (DESC)"])},
      "family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Famille (DESC)"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom (DESC)"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID (DESC)"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emplacement (DESC)"])},
      "matrix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrice (DESC)"])},
      "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méthode (DESC)"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom (DESC)"])},
      "nonCompliance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-conformité (DESC)"])},
      "observations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Observations (DESC)"])},
      "rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rang (DESC)"])},
      "recordStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["État de l'enregistrement (DESC)"])},
      "subChapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sous-chapitre (DESC)"])},
      "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom (DESC)"])},
      "wording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libellé (DESC)"])}
    }
  },
  "steppers": {
    "records": {
      "initScreen": {
        "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez choisir ce que vous aimeriez faire"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer l'enregistrement"])}
      },
      "steps": {
        "1": {
          "btnInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisez les boutons ci-dessous pour lier les enregistrements associés ou pour ignorer cette étape et créer un nouveau patient"])},
          "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisez la barre de recherche ci-dessous pour sélectionner un patient existant"])},
          "patientSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher des patients par nom ou numéro d'assurance"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner ou créer un patient"])}
        },
        "10": {
          "btnInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations supplémentaires"])}
        },
        "11": {
          "btnInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses d'enregistrement"])}
        },
        "12": {
          "btnInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Considérations particulières"])}
        },
        "2": {
          "btnInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de base sur le patient"])}
        },
        "3": {
          "btnInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Physiologie du patient"])}
        },
        "4": {
          "btnInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prise en charge assurance maladie et mutuelle"])}
        },
        "5": {
          "btnInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordonnées du patient"])}
        },
        "6": {
          "btnInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations supplémentaires sur le patient"])}
        },
        "7": {
          "btnInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez de nouvelles ressources si vous en avez besoin"])},
          "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renseignements sur le prélèvement"])}
        },
        "8": {
          "btnInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antécédents cliniques et posologie"])}
        },
        "9": {
          "btnInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options de rapport"])}
        }
      },
      "summary": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résumé du dossier"])}
      }
    }
  },
  "tabTitle": {
    "accountSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres du compte - Ninja LIMS"])},
    "addRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un enregistrement - Ninja LIMS"])},
    "analyses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses - Ninja LIMS"])},
    "appearanceSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres d'apparence - Ninja LIMS"])},
    "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturation - Ninja LIMS"])},
    "clinics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliniques - Ninja LIMS"])},
    "collectors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préleveurs - Ninja LIMS"])},
    "currentUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur actuel - Ninja LIMS"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de bord - Ninja LIMS"])},
    "dataManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des données - Ninja LIMS"])},
    "doctors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portail prescripteur - Ninja LIMS"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur - Ninja LIMS"])},
    "external_records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portail externe - Ninja LIMS"])},
    "families": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Familles - Ninja LIMS"])},
    "helpSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres d'aide - Ninja LIMS"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importation - Ninja LIMS"])},
    "informationSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres d'informations - Ninja LIMS"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion - Ninja LIMS"])},
    "machines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machines - Ninja LIMS"])},
    "matrices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrices - Ninja LIMS"])},
    "nabms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NABM - Ninja LIMS"])},
    "notificationSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres de notification - Ninja LIMS"])},
    "parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres - Ninja LIMS"])},
    "patients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patients - Ninja LIMS"])},
    "plainNinja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LIMS ninja"])},
    "prescribers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prescripteurs - Ninja LIMS"])},
    "previewRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu de l'enregistrement - Ninja LIMS"])},
    "records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossiers - Ninja LIMS"])},
    "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser le mot de passe - Ninja LIMS"])},
    "sample_types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Types d'échantillons - Ninja LIMS"])},
    "scanner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numériseur - Ninja LIMS"])},
    "securitySettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres de sécurité - Ninja LIMS"])},
    "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tâches"])},
    "techniques": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échantillons - Ninja LIMS"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs - Ninja LIMS"])}
  },
  "tables": {
    "analysesSearchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche par nom d'analyse, NABM ou nom de matrice"])},
    "analysisRequestForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bon de demande d'analyse"])},
    "as": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comme:"])},
    "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturation"])},
    "collectorSearchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche par nom ou prénom du préleveur"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter"])},
    "exportAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter tout dans la base de données"])},
    "exportFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format d'exportation"])},
    "familiesSearchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche par nom"])},
    "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["article(s)"])},
    "linesPerPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lignes par page"])},
    "machineSearchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher par nom de machine"])},
    "matricesSearchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche par nom de matrice"])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus"])},
    "nabmsSearchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche par code NABM, Coef B ou libellé NABM"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
    "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sur"])},
    "parametersSearchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche par rang de paramètre, nom, code, machine ou famille"])},
    "patientSearchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche par nom, prénom, date de naissance (JJ/MM/AAAA) ou sécurité sociale"])},
    "prescriberSearchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche par prénom, nom ou ADELI"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retourner"])},
    "printBarcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprimer l'étiquette du code-barres"])},
    "printExternalLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprimer l'étiquette externe"])},
    "printLabels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprimer des étiquettes"])},
    "printPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprimer l'étiquette du mot de passe"])},
    "recordSearchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche par prénom, nom, sécurité sociale ou numéro de dossier"])},
    "sampleTypeSearchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher par nom de type d'échantillon"])},
    "scanPrescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scanner l'ordonnance"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche"])},
    "searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher dans ce tableau"])},
    "techniqueSearchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche par ID, machine ou type d'échantillon"])}
  },
  "tabs": {
    "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Défaut"])},
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Femme"])},
    "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homme"])}
  },
  "tasks": {
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tâches en cours"])}
  },
  "techniques": {
    "addHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une technique"])},
    "deleteHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer technique"])},
    "disabledHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de la technique"])},
    "editHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technique d'édition"])},
    "exportHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Techniques d'exportation"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Techniques"])},
    "sampleObservations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Observations sur l'échantillon"])},
    "techniqueModalHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour manuellement l'échantillon"])},
    "techniqueResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats"])}
  },
  "toasts": {
    "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qu'est-ce que vous faites ?"])},
    "alreadyInUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone/email indisponible"])},
    "alreadyVerified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déjà vérifié"])},
    "cannotDowngradeStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de rétrograder le statut"])},
    "checkInsIdentity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identité validé avec succès"])},
    "downloadSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichier téléchargé !"])},
    "emailPendingVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail indisponible"])},
    "endValidationRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus aucun enregistrement à valider"])},
    "errorCheckInputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur! "])},
    "expiredToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeton expiré"])},
    "fetchedRecordFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichiers d'enregistrement stockés récupérés"])},
    "fileDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichier supprimé"])},
    "fileUploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichier téléchargé avec succès !"])},
    "getINS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations INS récupérées avec succès"])},
    "insNIA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["l'INS est de type NIA"])},
    "insufficientRights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Droits insuffisants"])},
    "insuranceExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention! L'assurance du patient a expiré"])},
    "insuranceExpiringSoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention! L'assurance du patient va bientôt expirer"])},
    "invalidCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["les informations d'identification invalides"])},
    "invalidDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date invalide"])},
    "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email invalide"])},
    "invalidInputType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certaines de vos entrées ne sont pas valides"])},
    "invalidLocale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres régionaux non valides"])},
    "invalidPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe incorrect"])},
    "invalidPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de portable invalide"])},
    "invalidSortName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de tri invalide"])},
    "invalidStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut invalide"])},
    "invalidTableName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de table invalide"])},
    "invalidTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre invalide"])},
    "invalidToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeton invalide"])},
    "invalidUUID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UUID invalide"])},
    "invalidatedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalidé avec succès"])},
    "missingData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données manquantes"])},
    "missingResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il manque un ou plusieurs résultats à l'échantillon. Êtes-vous sûr de vouloir le marquer comme terminé ? Si vous continuez, vous devrez invalider tous les échantillons liés à l'enregistrement pour pouvoir modifier à nouveau les résultats."])},
    "movingRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prochain enregistrement chargé à valider"])},
    "multipleInsIdentity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plusieurs identités trouvées"])},
    "mustChooseCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays non sélectionné"])},
    "nabmAlreadyInUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code NABM ne peut pas être utilisé"])},
    "ncaAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NCA ajouté !"])},
    "noAdminRights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun droit d'administrateur"])},
    "noInsIdentity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas d'identité trouvée"])},
    "otpExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP a expiré, demandez-en un nouveau"])},
    "otpNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun OTP trouvé pour votre compte"])},
    "passwordComplexity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe doit comporter au moins huit caractères, dont au moins une lettre, un chiffre et un caractère spécial."])},
    "passwordsDoNotMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mots de passe ne correspondent pas"])},
    "phonePendingVerOrLinking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifiez d'abord votre numéro de portable !"])},
    "precomputeSucceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats calculés avec succès !"])},
    "printSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichier imprimé !"])},
    "recordAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrement ajouté avec succès"])},
    "recordAlreadyScanned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrement déjà scanné"])},
    "recordDoesNotExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'enregistrement n'existe pas"])},
    "recordEdited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrement modifié"])},
    "resultsImportSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats importés avec succès !"])},
    "sampleUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échantillon mis à jour avec succès"])},
    "somethingWentWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelque chose s'est mal passé !"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réussi"])},
    "successAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouté avec succès"])},
    "successRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demandé avec succès"])},
    "successUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour réussie"])},
    "totallyWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelque chose s'est totalement mal passé !"])},
    "unexpectedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur inattendue! "])},
    "userDoesNotExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le compte n'existe pas"])},
    "validatedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validé avec succès"])},
    "verCodeNotExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le lien est invalide"])},
    "verLinkExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le lien a expiré"])}
  },
  "tooltips": {
    "addNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un nouveau"])},
    "addRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un enregistrement"])},
    "billingDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les détails de facturation"])},
    "cerfa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerfa"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
    "externalLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprimer étiquette externe"])},
    "filterResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer les résultats"])},
    "invalidateRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalider l'enregistrement"])},
    "invalidateSamples": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalider les échantillons"])},
    "moreOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus d'options"])},
    "prescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prescription"])},
    "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu"])},
    "printBarcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprimer l'étiquette code-barres"])},
    "printPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprimer le mot de passe"])},
    "recordSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher le résumé"])},
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualiser"])},
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport"])},
    "requestForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprimer bon de demande d'analyse"])},
    "scanPrescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scanner l'ordonnance"])},
    "showHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les résultats historiques"])},
    "tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivi"])},
    "uploadDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger des documents"])},
    "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider"])}
  },
  "trace": {
    "detailed_trace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trace détaillée"])},
    "log_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire du journal"])},
    "modification_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur"])},
    "modification_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champ"])},
    "modification_new_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle valeur"])},
    "modification_old_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancienne valeur"])},
    "modification_timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date et heure"])},
    "modification_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
    "nca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NCA"])}
  },
  "uploads": {
    "appearsInReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apparaît dans le rapport ?"])},
    "appendToReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter ce fichier au rapport d'enregistrement"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger"])},
    "existingFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichiers existants pour enregistrement"])},
    "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du fichier"])},
    "fileType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de fichier"])},
    "fileTypeSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le type de document que vous souhaitez télécharger"])},
    "fileTypes": {
      "driversLicense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PATIENT - Permis de conduire"])},
      "insuranceCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PATIENT - Carte d'assurance"])},
      "misc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DOSSIER - Divers"])},
      "nationalIdCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PATIENT - Carte d'identité nationale"])},
      "other_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PATIENT - Autre forme d'identité"])},
      "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PATIENT - Passeport"])},
      "patient_misc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PATIENT - Autres fichiers"])},
      "prescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DOSSIER - Prescription"])},
      "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DOSSIER - Résultats"])}
    },
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargements"])},
    "scanFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scanner le fichier"])},
    "scanOrSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez scanner ou sélectionner un fichier à télécharger"])},
    "seeUploads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les documents d'enregistrement"])},
    "uploadDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de téléchargement"])},
    "uploadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléverser un fichier"])},
    "uploadRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez un enregistrement pour lequel télécharger des documents"])}
  },
  "users": {
    "addHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un utilisateur"])},
    "deleteHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l'utilisateur"])},
    "disabledHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de l'utilisateur"])},
    "editHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier l'utilisateur"])},
    "exportHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter les utilisateurs"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs"])},
    "types": {
      "external": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externe"])},
      "internal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interne"])},
      "prescriber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prescripteur"])}
    }
  }
}