export default {
  "account": {
    "newEmailPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New email address"])},
    "newPhonePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New mobile number"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My account"])},
    "requestChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request change"])},
    "requestChangeEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request a change in email address"])},
    "requestChangePhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request a change in mobile number"])},
    "requestVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request verification"])},
    "requestVerificationEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request email address verification"])},
    "requestVerificationPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request mobile number verification"])},
    "unverified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unverified"])},
    "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verified"])}
  },
  "analyses": {
    "addHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add an analysis"])},
    "deleteHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete analysis"])},
    "disabledHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis details"])},
    "editHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify the analysis"])},
    "exportHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export the analyses"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses"])}
  },
  "analysisResultsTable": {
    "anteriority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteriority"])},
    "calculatedResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculated results"])},
    "enterPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter results here"])},
    "parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter"])},
    "rawResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raw results"])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refererence"])},
    "referenceRangeCalculated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculated reference range"])},
    "referenceRangeRaw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raw reference range"])},
    "units": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Units"])},
    "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])}
  },
  "anes": {
    "ane1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis carried out later"])},
    "ane10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical incident"])},
    "ane11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No parental authorization"])},
    "ane12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modification of the prescription by the biologist"])},
    "ane2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis not carried out at the patient's request"])},
    "ane3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses outside nomenclatures not carried out at the patient's request"])},
    "ane4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing sample(s)"])},
    "ane5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-analytical conditions not respected"])},
    "ane6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis already carried out"])},
    "ane7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis canceled"])},
    "ane8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient not fasting"])},
    "ane9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis not carried out in the laboratory"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])}
  },
  "appearance": {
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "colorBlindMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colorblind mode"])},
    "deactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactivated"])},
    "display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display"])},
    "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example :"])},
    "exampleText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sars-cov not detected"])},
    "fontSizes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Font sizes"])},
    "nightMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Night mode"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appearance"])}
  },
  "btns": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "addParameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add parameter"])},
    "addRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add record"])},
    "annulr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulr"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "cerfa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerfa"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "confirmAndReturnToDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm and return to dashboard"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "deleteRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete data"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "editParameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify parameter"])},
    "editPatient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Record"])},
    "editRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit record"])},
    "editRecordAnalyses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit record analyses"])},
    "findRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find record"])},
    "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import"])},
    "invalidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalidate"])},
    "invalidateSamples": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalidate samples"])},
    "manageANEs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage ANEs"])},
    "manageNCAs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage NCAs"])},
    "mergeDuplicates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merge duplicates"])},
    "newRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New record"])},
    "precomputeResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculate results"])},
    "prescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prescription"])},
    "receive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "seeLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See less"])},
    "seeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See more"])},
    "selectFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a file"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "showSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show summary"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "submitChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit changes"])},
    "tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracking"])},
    "updateTodo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update to do list"])},
    "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate"])}
  },
  "civilities": {
    "BBF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female baby"])},
    "BBG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male baby"])},
    "CIQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CIQ"])},
    "EEQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EEQ"])},
    "ENFF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infant boy"])},
    "ENFG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infant girl"])},
    "MME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ms"])},
    "MR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mr"])}
  },
  "clinics": {
    "addHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add clinic"])},
    "deleteHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete clinic(s)"])},
    "disabledHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clinic details"])},
    "editHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit clinic"])},
    "exportHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export clinic(s)"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clinics"])}
  },
  "collectors": {
    "addHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add collector"])},
    "deleteHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete collector(s)"])},
    "disabledHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collector details"])},
    "editHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit collector"])},
    "exportHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export collector(s)"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collectors"])}
  },
  "countries": {
    "BonaireSintEustatiusAndSaba": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonaire, Sint Eustatius and Saba"])},
    "ChinaHongKongSpecialAdministrativeRegion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["China, Hong Kong Special Administrative Region"])},
    "ChinaMacaoSpecialAdministrativeRegion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["China, Macao Special Administrative Region"])},
    "afghanistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afghanistan"])},
    "alandIslands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åland Islands"])},
    "albania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Albania"])},
    "algeria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algeria"])},
    "americanSamoa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["American Samoa"])},
    "andorra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andorra"])},
    "angola": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angola"])},
    "anguilla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anguilla"])},
    "antarctica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antarctica"])},
    "antiguaAndBarbuda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antigua and Barbuda"])},
    "argentina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argentina"])},
    "armenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Armenia"])},
    "aruba": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aruba"])},
    "australia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Australia"])},
    "austria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Austria"])},
    "azerbaijan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azerbaijan"])},
    "bahamas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahamas"])},
    "bahrain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahrain"])},
    "bangladesh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bangladesh"])},
    "barbados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barbados"])},
    "belarus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belarus"])},
    "belgium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgium"])},
    "belize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belize"])},
    "benin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benin"])},
    "bermuda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bermuda"])},
    "bhutan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bhutan"])},
    "boliviaPlurinationalStateOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolivia (Plurinational State of)"])},
    "bosniaAndHerzegovina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bosnia and Herzegovina"])},
    "botswana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botswana"])},
    "bouvetIsland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bouvet Island"])},
    "brazil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brazil"])},
    "britishIndianOceanTerritory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["British Indian Ocean Territory"])},
    "britishVirginIslands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["British Virgin Islands"])},
    "bruneiDarussalam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brunei Darussalam"])},
    "bulgaria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulgaria"])},
    "burkinaFaso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burkina Faso"])},
    "burundi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burundi"])},
    "caboVerde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cabo Verde"])},
    "cambodia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambodia"])},
    "cameroon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cameroon"])},
    "canada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canada"])},
    "caymanIslands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cayman Islands"])},
    "centralAfricanRepublic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Central African Republic"])},
    "chad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chad"])},
    "chile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chile"])},
    "china": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["China"])},
    "christmasIsland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Christmas Island"])},
    "cocosKeelingIslands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cocos (Keeling) Islands"])},
    "colombia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colombia"])},
    "comoros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comoros"])},
    "congo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congo"])},
    "cookIslands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cook Islands"])},
    "costaRica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costa Rica"])},
    "coteDIvoire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Côte d'Ivoire"])},
    "croatia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croatia"])},
    "cuba": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuba"])},
    "curacao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curaçao"])},
    "cyprus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cyprus"])},
    "czechia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czechia"])},
    "democraticPeoplesRepublicOfKorea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Democratic People's Republic of Korea"])},
    "democraticRepublicOfTheCongo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Democratic Republic of the Congo"])},
    "denmark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denmark"])},
    "djibouti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Djibouti"])},
    "dominica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominica"])},
    "dominicanRepublic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominican Republic"])},
    "ecuador": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ecuador"])},
    "egypt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egypt"])},
    "elSalvador": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Salvador"])},
    "equatorialGuinea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equatorial Guinea"])},
    "eritrea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eritrea"])},
    "estonia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estonia"])},
    "eswatini": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eswatini"])},
    "ethiopia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ethiopia"])},
    "falklandIslandsMalvinas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falkland Islands (Malvinas)"])},
    "faroeIslands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faroe Islands"])},
    "fiji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiji"])},
    "finland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finland"])},
    "france": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["France"])},
    "frenchGuiana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French Guiana"])},
    "frenchPolynesia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French Polynesia"])},
    "frenchSouthernTerritories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French Southern Territories"])},
    "gabon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gabon"])},
    "gambia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gambia"])},
    "georgia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Georgia"])},
    "germany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Germany"])},
    "ghana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghana"])},
    "gibraltar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gibraltar"])},
    "greece": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greece"])},
    "greenland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greenland"])},
    "grenada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grenada"])},
    "guadeloupe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guadeloupe"])},
    "guam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guam"])},
    "guatemala": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guatemala"])},
    "guernsey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guernsey"])},
    "guinea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinea"])},
    "guineaBissau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinea-Bissau"])},
    "guyana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guyana"])},
    "haiti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haiti"])},
    "heardIslandAndMcDonaldIslands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heard Island and McDonald Islands"])},
    "holySee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holy See"])},
    "honduras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honduras"])},
    "hungary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hungary"])},
    "iceland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iceland"])},
    "india": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["India"])},
    "indonesia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indonesia"])},
    "iranIslamicRepublicOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iran (Islamic Republic of)"])},
    "iraq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iraq"])},
    "ireland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ireland"])},
    "isleOfMan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isle of Man"])},
    "israel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Israel"])},
    "italy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italy"])},
    "jamaica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jamaica"])},
    "japan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japan"])},
    "jersey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jersey"])},
    "jordan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jordan"])},
    "kazakhstan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kazakhstan"])},
    "kenya": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kenya"])},
    "kiribati": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiribati"])},
    "kuwait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuwait"])},
    "kyrgyzstan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyrgyzstan"])},
    "laoPeoplesDemocraticRepublic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lao People's Democratic Republic"])},
    "latvia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latvia"])},
    "lebanon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lebanon"])},
    "lesotho": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesotho"])},
    "liberia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liberia"])},
    "libya": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libya"])},
    "liechtenstein": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liechtenstein"])},
    "lithuania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lithuania"])},
    "luxembourg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxembourg"])},
    "madagascar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madagascar"])},
    "malawi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malawi"])},
    "malaysia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malaysia"])},
    "maldives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maldives"])},
    "mali": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mali"])},
    "malta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malta"])},
    "marshallIslands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marshall Islands"])},
    "martinique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Martinique"])},
    "mauritania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauritania"])},
    "mauritius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauritius"])},
    "mayotte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mayotte"])},
    "mexico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mexico"])},
    "micronesiaFederatedStatesOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Micronesia (Federated States of)"])},
    "monaco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monaco"])},
    "mongolia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mongolia"])},
    "montenegro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montenegro"])},
    "montserrat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montserrat"])},
    "morocco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Morocco"])},
    "mozambique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mozambique"])},
    "myanmar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myanmar"])},
    "namibia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namibia"])},
    "nauru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nauru"])},
    "nepal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepal"])},
    "netherlands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netherlands"])},
    "newCaledonia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Caledonia"])},
    "newZealand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Zealand"])},
    "nicaragua": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicaragua"])},
    "niger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niger"])},
    "nigeria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nigeria"])},
    "niue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niue"])},
    "norfolkIsland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norfolk Island"])},
    "northernMarianaIslands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Northern Mariana Islands"])},
    "norway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norway"])},
    "oman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oman"])},
    "pakistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakistan"])},
    "palau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palau"])},
    "panama": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panama"])},
    "papuaNewGuinea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papua New Guinea"])},
    "paraguay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paraguay"])},
    "peru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peru"])},
    "philippines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Philippines"])},
    "pitcairn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pitcairn"])},
    "poland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poland"])},
    "portugal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugal"])},
    "puertoRico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puerto Rico"])},
    "qatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qatar"])},
    "republicOfKorea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Republic of Korea"])},
    "republicOfMoldova": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Republic of Moldova"])},
    "reunion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réunion"])},
    "romania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romania"])},
    "russianFederation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russian Federation"])},
    "rwanda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rwanda"])},
    "saintBarthelemy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Barthélemy"])},
    "saintHelena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Helena"])},
    "saintKittsAndNevis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Kitts and Nevis"])},
    "saintLucia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Lucia"])},
    "saintMartinFrenchPart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Martin (French Part)"])},
    "saintPierreAndMiquelon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Pierre and Miquelon"])},
    "saintVincentAndTheGrenadines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Vincent and the Grenadines"])},
    "samoa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samoa"])},
    "sanMarino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["San Marino"])},
    "saoTomeAndPrincipe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sao Tome and Principe"])},
    "saudiArabia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saudi Arabia"])},
    "senegal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senegal"])},
    "serbia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbia"])},
    "seychelles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seychelles"])},
    "sierraLeone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sierra Leone"])},
    "singapore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singapore"])},
    "sintMaartenDutchPart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sint Maarten (Dutch part)"])},
    "slovakia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovakia"])},
    "slovenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovenia"])},
    "solomonIslands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solomon Islands"])},
    "somalia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somalia"])},
    "southAfrica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["South Africa"])},
    "southGeorgiaAndTheSouthSandwichIslands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["South Georgia and the South Sandwich Islands"])},
    "southSudan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["South Sudan"])},
    "spain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spain"])},
    "sriLanka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sri Lanka"])},
    "stateOfPalestine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State of Palestine"])},
    "sudan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sudan"])},
    "suriname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suriname"])},
    "svalbardAndJanMayenIslands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svalbard and Jan Mayen Islands"])},
    "sweden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sweden"])},
    "switzerland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switzerland"])},
    "syrianArabRepublic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syrian Arab Republic"])},
    "taiwan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taiwan"])},
    "tajikistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tajikistan"])},
    "thailand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thailand"])},
    "theFormerYugoslavRepublicOfMacedonia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The former Yugoslav Republic of Macedonia"])},
    "timorLeste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timor-Leste"])},
    "togo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Togo"])},
    "tokelau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokelau"])},
    "tonga": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tonga"])},
    "trinidadAndTobago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trinidad and Tobago"])},
    "tunisia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunisia"])},
    "turkey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkey"])},
    "turkmenistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkmenistan"])},
    "turksAndCaicosIslands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turks and Caicos Islands"])},
    "tuvalu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuvalu"])},
    "uganda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uganda"])},
    "ukraine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukraine"])},
    "unitedArabEmirates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["United Arab Emirates"])},
    "unitedKingdomOfGreatBritainAndNorthernIreland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["United Kingdom of Great Britain and Northern Ireland"])},
    "unitedRepublicOfTanzania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["United Republic of Tanzania"])},
    "unitedStatesMinorOutlyingIslands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["United States Minor Outlying Islands"])},
    "unitedStatesOfAmerica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["United States of America"])},
    "unitedStatesVirginIslands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["United States Virgin Islands"])},
    "uruguay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uruguay"])},
    "uzbekistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzbekistan"])},
    "vanuatu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vanuatu"])},
    "venezuelaBolivarianRepublicOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venezuela (Bolivarian Republic of)"])},
    "vietNam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viet Nam"])},
    "wallisAndFutunaIslands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallis and Futuna Islands"])},
    "westernSahara": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Western Sahara"])},
    "yemen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yemen"])},
    "zambia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zambia"])},
    "zimbabwe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zimbabwe"])}
  },
  "dashboard": {
    "notifications": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])}
    },
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "quarterlyStats": {
      "annulr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canceled and replaced"])},
      "numExternalRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External records"])},
      "numRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Records added"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quarterly statistics"])}
    },
    "quickAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick access"])},
    "seeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See"])},
    "stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics"])},
    "summary1": {
      "stat1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In progress"])},
      "stat2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parked"])},
      "stat3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])}
    },
    "summary2": {
      "stat1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently being analyzed"])},
      "stat2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-registered"])},
      "stat3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis types"])}
    },
    "summary3": {
      "stat1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently being analyzed"])},
      "stat2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-registered"])},
      "stat3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CL / TES / CLB"])}
    },
    "summaryOfTheDay": {
      "stat1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patients added"])},
      "stat2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samples processed"])},
      "stat3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses completed"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here's what's happened since this morning:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary of the day"])}
    },
    "tasksInProgress": {
      "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongoing records"])},
      "sentExternally": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Records sent externally"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasks in progress"])},
      "toFax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Records to fax"])},
      "toInsuranceReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance reports to process"])},
      "toOnlineReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online reports to process"])},
      "toPaperReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paper reports to process"])},
      "toPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Records to phone"])},
      "toPhysicianReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Physician reports to process"])},
      "toPost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Records to post"])},
      "toValidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Records to validate"])},
      "urgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urgent records"])},
      "validatedToday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Records validated today"])},
      "withANE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Records with ANEs"])},
      "withLbloc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Records with LBLOC"])},
      "withNCA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Records with NCAs"])},
      "withNCT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Records with NCTs"])}
    }
  },
  "dataManagement": {
    "allInternalNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal notes of all patients"])},
    "confirmDeleting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please confirm you are going to delete the above patient(s) which have been highlighted in red. Please note once deleted this is irretrievable:"])},
    "confirmKeeping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please confirm you are going to keep all the above records. This will no longer be marked as a duplicate record."])},
    "deleting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting"])},
    "duplicateModals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resolve duplicates"])},
    "internalNotesToKeep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal notes to keep"])},
    "keeping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keeping"])},
    "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data management"])},
    "searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search table..."])},
    "selecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecting"])},
    "submitWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If choosing a patient to delete you must also select a patient which will be the replacement before submitting"])}
  },
  "ems": {
    "accessTokenExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access token expired"])},
    "adminNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin does not exist"])},
    "alreadyVerified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already verified"])},
    "analysisIncomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis data in DB is incomplete"])},
    "analysisNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis does not exist"])},
    "authNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auth session does not exist"])},
    "backupNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backup does not exist"])},
    "cannotChangeSelectedAnalysesStructure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot change selected analysis structure"])},
    "cannotDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot delete this type of file"])},
    "cannotDowngradeStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot downgrade status"])},
    "cannotEditLock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot edit imported records from previous Ninja versions"])},
    "cannotOrderHiddenAnalyses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot order hidden analyses"])},
    "cannotUpdateRightsOfExternalUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot update rights of external user"])},
    "cannotUpdateVerifiedEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot update email of verified account"])},
    "clinicNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clinic does not exist"])},
    "clinicUserOverreached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clinic users can only access their assets"])},
    "codeAlreadyInUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code is already in use"])},
    "collectorNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collector does not exist"])},
    "csvExportFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV export failed"])},
    "duplicateNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate does not exist"])},
    "emailAlreadyInUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email is already in use"])},
    "emailPendingVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email pending verification or in use"])},
    "familyNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family does not exist"])},
    "formatterNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format function does not exist"])},
    "incorrectCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect credentials"])},
    "incorrectEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email is incorrect or invalid"])},
    "incorrectOtp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect otp"])},
    "incorrectPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile number is incorrect or invalid"])},
    "incorrectType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect type for one or more parameters"])},
    "insInfoNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not retrieve INS information"])},
    "insStatusNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not verify INS status"])},
    "insufficientAdminRights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have insufficient admin rights"])},
    "invalidAccessSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid settings"])},
    "invalidAccessToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid access token"])},
    "invalidApiToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid token"])},
    "invalidBounds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter configured with invalid bounds in BE"])},
    "invalidClinicCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clinic code is invalid"])},
    "invalidListOfPatientsToDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid list of patients to delete"])},
    "invalidName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name is invalid"])},
    "invalidOperation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attempted invalid operation"])},
    "invalidOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid option selected"])},
    "invalidRecordStatusForInvalidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record status prevents invalidation"])},
    "invalidRecordStatusForUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record is not ready to be updated"])},
    "invalidRecordStatusForValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record is not ready for validation"])},
    "invalidRefreshToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid refresh token"])},
    "laboratoryNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laboratory does not exist"])},
    "machineNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machine does not exist"])},
    "matrixNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrix does not exist"])},
    "missingData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DB request missing data"])},
    "missingVerifiedPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please verify your mobile number"])},
    "mustCloseNct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample has active NCT"])},
    "nabmNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NABM does not exist"])},
    "nameTaken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name for asset is taken"])},
    "ncaNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NCA does not exist"])},
    "noAdminRights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have no admin rights"])},
    "noTablesToBackup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tables to backup"])},
    "optionsPresetNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options preset does not exist"])},
    "otpExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otp has expired"])},
    "otpNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otp has not been requested"])},
    "outOfBounds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter is out of bounds"])},
    "parameterIncomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter data in DB is incomplete"])},
    "parameterNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter does not exist"])},
    "patientNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient does not exist"])},
    "phoneAlreadyInUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone is already in use"])},
    "prescriberNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prescriber does not exist"])},
    "recordAlreadyScanned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already scanned"])},
    "recordIdCreationFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record creation failed"])},
    "recordIncomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record data in DB is incomplete"])},
    "recordNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record does not exist"])},
    "reportsApiReqFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request to reports API failed"])},
    "resetExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset expired"])},
    "resetNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset entry does not exist"])},
    "routeConfigNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Route does not exist in BE"])},
    "sampleIncomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample data in DB is incomplete"])},
    "sampleNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample does not exist"])},
    "sampleTypeNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample type does not exist"])},
    "smartApiReqFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request to smart API failed"])},
    "templateNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template does not exist"])},
    "typeCheckFunNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type check function does not exist"])},
    "userNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User does not exist"])},
    "verificationExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification link has expired"])},
    "verificationNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification item does not exist"])}
  },
  "export": {
    "addHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add export"])},
    "deleteHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete export"])},
    "disabledHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export details"])},
    "editHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit export"])},
    "exportHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export records"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])}
  },
  "families": {
    "addHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a family"])},
    "deleteHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete families"])},
    "disabledHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family details"])},
    "editHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit family"])},
    "exportHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export families"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Families"])}
  },
  "filterOptions": {
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
    "byClinic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by clinic"])},
    "byCollector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by collector"])},
    "byFamily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by family"])},
    "byMachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by machine"])},
    "byMatrix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by matrix"])},
    "byPrescriber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by prescriber"])},
    "createdFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created from"])},
    "createdTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
    "hiddenAnalyses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal analyses"])},
    "hiddenInReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hidden in report"])},
    "visibleAnalyses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public analyses"])},
    "visibleInReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visible in report"])}
  },
  "formFields": {
    "updateComments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update comments"])},
    "notAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not available"])},
    "linkedSample": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linked sample"])},
    "accessAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin access"])},
    "addANE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new ANE"])},
    "additionalSamples": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional samples"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age (years)"])},
    "analysesNames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses names"])},
    "analysisAllocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample allocation (%)"])},
    "analysisCustomCost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional/custom cost (€)"])},
    "analysisName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis name"])},
    "aneAnalysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANE analysis"])},
    "aneCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANE code"])},
    "basicDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic details"])},
    "billingUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing user"])},
    "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civility"])},
    "clinic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clinic"])},
    "clinicalHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clinico-biological information"])},
    "collectorCreateNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new collector"])},
    "collector_billed_by_lab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billed by the lab"])},
    "collector_billing_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing ID number"])},
    "collector_call_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call code"])},
    "collector_conventional_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conventional code"])},
    "collector_ged_doc_diff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GED document diffusion"])},
    "collector_ik_zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IK zone"])},
    "collector_internal_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal code"])},
    "collector_obsolete_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obsolete from"])},
    "collector_practice_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Practice conditions"])},
    "collector_pricing_zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing zone"])},
    "collector_qualification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualification"])},
    "collector_structure_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Structure number"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm password"])},
    "confirmSamplingTimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm sampling times"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "countryCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country code"])},
    "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation date"])},
    "dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dates"])},
    "dob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of birth"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "externalSamples": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Includes external samples?"])},
    "externalUserType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account type"])},
    "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["False"])},
    "familyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family name"])},
    "familyRank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rank in report"])},
    "familyReportMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report message"])},
    "family_is_envoi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List analyses as external in report?"])},
    "faxNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax number"])},
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
    "filterAndSort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter and Sort"])},
    "filterBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "getIns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retrieve INH"])},
    "governmentINS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrated National Health Identity (INH)"])},
    "governmentINSType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INH type"])},
    "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height (cm)"])},
    "hideInReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide in report"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "insurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient's insurance"])},
    "insuranceAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agreement type"])},
    "internal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal"])},
    "internalNotesPatient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal notes of the patient"])},
    "internalNotesRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal notes of the record"])},
    "invalidationAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalidation admin"])},
    "isAccredited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accredited?"])},
    "iso2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISO 2"])},
    "iso3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISO 3"])},
    "laboratory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laboratory"])},
    "landline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Landline number"])},
    "lastUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last updated"])},
    "lbloc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LBloc"])},
    "lblocDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LBloc description"])},
    "lblocFlag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LBloc ?"])},
    "machineCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machine code"])},
    "machineLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location of the machine"])},
    "machineName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machine name"])},
    "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
    "matrixName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biological matrix"])},
    "medicalUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medical user"])},
    "nabm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NABM"])},
    "nabmActesReserves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actes reserves"])},
    "nabmAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agreement"])},
    "nabmBioInitiative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bio initiative"])},
    "nabmBloodTest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blood test"])},
    "nabmChapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter"])},
    "nabmCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "nabmCoefB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coef B"])},
    "nabmConflictsExplanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NABM conflicts were found and are listed below. For conflicts between different analyses, please go back to the record creation and remove some of the conflicting analyses. For conflicts within the same analysis, this means that the analysis was misconfigured at the time of creation. To fix this issue, please edit the conflicting analysis in the analyses page."])},
    "nabmContingency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contingency"])},
    "nabmIncompatibleCodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incompatible codes"])},
    "nabmLastEeetDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last EEET Date"])},
    "nabmMaxNumCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max num code"])},
    "nabmMedIndicRef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Med indic ref"])},
    "nabmRefund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund"])},
    "nabmRmo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RMO"])},
    "nabmSpecRuleNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spec rule num"])},
    "nabmSubChapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subchapter"])},
    "nabmWording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wording"])},
    "nabms100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NABMs 100"])},
    "nabms60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NABMs 60"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "nameAtBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surname at birth"])},
    "ncaCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "ncaDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "ncaDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NCA details"])},
    "ncaResolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resolved?"])},
    "ncaSolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution"])},
    "nct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NCT"])},
    "nctCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NCT code"])},
    "nctDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NCT description"])},
    "nctResolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NCT active ?"])},
    "nctSolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NCT solution"])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
    "newValues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New values"])},
    "newborn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newborn"])},
    "noANEs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No ANEs have been raised for this record"])},
    "noNCAs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No NCAs have been raised for this record"])},
    "numberToPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number to phone"])},
    "oldValues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old values"])},
    "otherPeopleToPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other person(s) to be phoned"])},
    "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One time password"])},
    "outOfRangeWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This result is out of range. The valid range properties for this patient are:"])},
    "parameterDecimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decimal places in results"])},
    "parameterFormula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formula"])},
    "parameterFormulaUnits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Units after calculation"])},
    "parameterHasFormula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter has formula?"])},
    "parameterLowerDetectionLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lower detection limit"])},
    "parameterMatrix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrix"])},
    "parameterMatrixRangeComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
    "parameterMatrixRangeDelta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delta"])},
    "parameterMatrixRangeFemale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Range for adult females"])},
    "parameterMatrixRangeFormulaComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post-conversion comment"])},
    "parameterMatrixRangeMale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Range for adult males"])},
    "parameterMatrixRangeMax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max"])},
    "parameterMatrixRangeMin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min"])},
    "parameterMatrixRangeNewborn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Range for newborns"])},
    "parameterMatrixRangePregnant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Range for pregnat individuals"])},
    "parameterMatrixRangeUseDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use default range only"])},
    "parameterMatrixRange_0_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Range for ages 0 to 1"])},
    "parameterMatrixRange_1_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Range for ages 1 to 6"])},
    "parameterMatrixRange_6_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Range for ages 6 to 15"])},
    "parameterMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Method"])},
    "parameterName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter name"])},
    "parameterOpenFormulaModal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open formula interface"])},
    "parameterRank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rank"])},
    "parameterShowConversionReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show before and after in report?"])},
    "parameterTechSheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical sheet"])},
    "parameterUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raw unit"])},
    "parameterUpperDetectionLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upper detection limit"])},
    "parameterUrineEch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urine sample"])},
    "parameterValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter value"])},
    "parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Molecules"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "passwordNoMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords do not match."])},
    "passwordReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
    "patientConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient conditions are:"])},
    "patientCreateNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new patient"])},
    "patientInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient information"])},
    "patientNameSurname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by patient name, surname, social security, or record ID"])},
    "patientSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for a patient"])},
    "patient_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External ID"])},
    "patient_insurance_code1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance code 1"])},
    "patient_insurance_code2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance code 2"])},
    "patient_insurance_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance from"])},
    "patient_insurance_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance to"])},
    "patient_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clinic"])},
    "patient_posology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posology"])},
    "patient_treatment_last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date and time of last treatment"])},
    "peopleToPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person(s) to be phoned"])},
    "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile number"])},
    "placeOfBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place of birth (FR post code)"])},
    "postCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip code"])},
    "pregnant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pregnant"])},
    "prescriber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prescriber"])},
    "prescriberAdeli": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADELI number"])},
    "prescriberCreateNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new prescriber"])},
    "prescriberSpecialtyCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medical specialty code"])},
    "prescriptionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prescription date"])},
    "public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public"])},
    "quickSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick selection"])},
    "quoting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample allocation (%)"])},
    "recordAnalyses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record analyses"])},
    "recordAnnulrReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulment explanation"])},
    "recordDelaiActualTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delai actual time"])},
    "recordDelaiCause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delai cause"])},
    "recordDelaiExpectedTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delai expected time"])},
    "recordDelaiFlag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delai?"])},
    "recordDelaiImpact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delai impact on patient"])},
    "recordDerogDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DEROG description"])},
    "recordDerogFlag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DEROG?"])},
    "recordFaxNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax number"])},
    "recordInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record information"])},
    "recordInterpretation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record interpretation"])},
    "recordIsUrgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record is urgent?"])},
    "recordMustFax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax results"])},
    "recordMustInsuranceReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance report"])},
    "recordMustOccupationalPhysician": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occupational physician"])},
    "recordMustOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online report"])},
    "recordMustPaper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paper report"])},
    "recordMustPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone results"])},
    "recordMustPost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail results"])},
    "recordNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record number"])},
    "recordNumbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record Numbers"])},
    "recordOralFlag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oral prescription?"])},
    "recordPendingFax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending faxing of results"])},
    "recordPendingInsuranceReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending insurance report"])},
    "recordPendingOccupationalPhysician": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending physician report"])},
    "recordPendingOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending online report"])},
    "recordPendingPaper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending paper report"])},
    "recordPendingPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending phone call"])},
    "recordPendingPost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending postage of results"])},
    "recordUhExpectedTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UH expected time"])},
    "recordUhFlag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UH?"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
    "requiresSamplingTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requires sampling time?"])},
    "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result"])},
    "rppsNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RPPS number"])},
    "sOid": {
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testing INS-NIR assignment authority"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demonstration INS-NIR assignment authority"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INS-NIR assignment authority"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INS-NIA assignment authority"])}
    },
    "sampleCollectedExternally": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samples collected externally?"])},
    "sampleCollectionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collection date and time"])},
    "sampleCollector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collector"])},
    "sampleInjected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Injected"])},
    "sampleInterpretation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample interpretation"])},
    "sampleNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample number"])},
    "samplePrepared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prepared"])},
    "sampleRemoved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Removed"])},
    "sampleReportSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report sent"])},
    "sampleType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of sample"])},
    "sampleTypeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description of the sample"])},
    "sampleTypeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample Name"])},
    "sampleValidated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validated"])},
    "samplingTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sampling time"])},
    "sex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sex"])},
    "socialSecurity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social security number"])},
    "sortBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort by"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "summaryOfChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary of changes"])},
    "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surname"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "titleDoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dr"])},
    "titleInf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inf"])},
    "titleMed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Med"])},
    "titleMiss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miss"])},
    "titleMlle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mlle"])},
    "titleMme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mme"])},
    "titleMr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mr"])},
    "titleMrs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mrs"])},
    "titleMs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ms"])},
    "todoList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To do list"])},
    "treatingPractitioner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treating practitioner"])},
    "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["True"])},
    "unresolvedNCA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unresolved NCAs"])},
    "uploadBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload your .csv file here. Simply click on this text or drag and drop your file to get started."])},
    "userType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User type"])},
    "validationAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validation admin"])},
    "visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibility"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight (kg)"])}
  },
  "help": {
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "contactUsInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription, contact the administrators.."])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "onlineTutorials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online tutorials"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
    "tutorialInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need help? Check out our videos explaining each Ninja tool."])}
  },
  "import": {
    "THC-COOH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["THC-COOH"])},
    "acheminement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forwarding"])},
    "chlamydia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chlamydia"])},
    "confirmImport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import sample results"])},
    "ecbu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECBU"])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select file"])},
    "fileType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File Type"])},
    "groupage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BLOOD GROUP"])},
    "icpms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ICPMS"])},
    "moleculesFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Molecules found"])},
    "mstPcr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MST"])},
    "mycoplasma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mycoplasma"])},
    "neisseria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neisseria"])},
    "noFileSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No file selected"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import"])},
    "parametersFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameters found"])},
    "pcrMst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STD PCR"])},
    "pcrResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PCR RESULTS COVID-19"])},
    "pcrRhino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RHINO-PHARYNGEAL PCR RECORDS"])},
    "pcrSaliva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SALIVA PCR RECORDS"])},
    "qualitative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QUALITATIVE"])},
    "qualitativeLcQtof": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LCQTOF QUALITATIVE"])},
    "quantitative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QUANTITATIVE"])},
    "rai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAI"])},
    "thcD3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["THC-d3 (ISTD)"])}
  },
  "info": {
    "accreditations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accreditations"])},
    "ninja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ninja LIMS"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
    "paragraph1Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introducing Ninja LIMS, the laboratory management software developed by Zedsoft."])},
    "paragraph2Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With Ninja LIMS, you can easily manage and automate your laboratory operations. Our software helps to streamline your workflow so you can get the most out of your laboratory. You can easily track, store and share data, manage samples, and generate reports."])},
    "paragraph3Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We provide powerful modules and features to help you optimize your lab's efficiency. With our intuitive design, you can quickly and easily set up, configure, and customize the software to meet your needs."])},
    "paragraph4Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus, Ninja LIMS is highly secure, making sure your data is always safe and secure. With our advanced security protocols, you can be sure that your data is safe from malicious threats."])},
    "paragraph5Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't wait any longer - get Ninja LIMS and start optimizing your laboratory management today!"])}
  },
  "login": {
    "backToConnection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to login"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password"])},
    "forgotPasswordPrompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the email address you use to login, we will send a password recovery email to that address."])},
    "forgotPasswordQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password?"])},
    "otpPrompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have sent a one-time password to your mobile, please enter it here to login."])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greetings! Your cloud-based lab assistant awaits. Please login to your account."])},
    "productOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A product by"])}
  },
  "machines": {
    "addHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a machine"])},
    "deleteHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete machine(s)"])},
    "disabledHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machine details"])},
    "editHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit machine"])},
    "exportHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export machine(s)"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machines"])}
  },
  "matrices": {
    "addHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a matrix"])},
    "deleteHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete the matrix"])},
    "disabledHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrix details"])},
    "editHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Matrix"])},
    "exportHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export the matrices"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrices"])}
  },
  "misc": {
    "accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounts"])},
    "addRecordSubtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new record/patient ..."])},
    "adminPermissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PERMISSIONS"])},
    "adminSpecialRights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SPECIAL RIGHTS"])},
    "compteSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User settings"])},
    "copySuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text copied to clipboard"])},
    "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
    "dataSubText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete folders or duplicates ..."])},
    "defaultFormula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default formula"])},
    "defaultResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default results"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read, edit and delete"])},
    "deleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted successfully"])},
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ERROR"])},
    "femaleFormula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female formula"])},
    "femaleResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female results"])},
    "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
    "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formula"])},
    "french": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])},
    "genderSpecificFormula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender specific formulas"])},
    "inactivityLogout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have been logged out due to inactivity"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "maleFormula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male formula"])},
    "maleResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male results"])},
    "noPermissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No permissions"])},
    "patient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient"])},
    "patients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patients"])},
    "presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presentation"])},
    "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read"])},
    "recordsDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Records deleted"])},
    "recordsReceived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Records received"])},
    "requiredParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SELECT REQUIRED PARAMETERS"])},
    "returnToDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We could not find the requested page. Try our Dashboard instead."])},
    "returnToDashboardButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to dashboard"])},
    "samplingGuide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sampling guide"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "settingsSubText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change user details..."])},
    "testFormula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test formula"])},
    "themeToggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change theme"])},
    "uiOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UI Options"])},
    "verificationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click button to return to dashboard"])},
    "verificationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification complete"])},
    "whatToDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What would you like to do?"])},
    "write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read and edit"])}
  },
  "nabms": {
    "addHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add NABM"])},
    "deleteHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete NABM"])},
    "disabledHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NABM details"])},
    "editHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit NABM"])},
    "exportHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export NABMs"])},
    "nonRefundable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-refundable NABMs"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NABMs"])},
    "refundable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refundable NABMs"])}
  },
  "ncas": {
    "nca1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrative information missing or incorrect"])},
    "nca2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External transmission form missing or incomplete"])},
    "nca3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identity error"])},
    "nca4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obviously analyzed"])},
    "nca5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discordant identity"])},
    "nca6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parse error"])},
    "nca7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order illegible"])},
    "nca8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot or transmission error"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])}
  },
  "ncts": {
    "nct1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing sample"])},
    "nct2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insufficient collection"])},
    "nct3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-compliant sample"])},
    "nct4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tube and container expired"])},
    "nct5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-analytical timeout exceeded"])},
    "nct6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample incorrectly/unidentified"])},
    "nct7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clinical intelligence"])},
    "nct8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result rendering delay"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])}
  },
  "notifications": {
    "frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequency :"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
    "push": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Push"])}
  },
  "parameters": {
    "addHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a parameter"])},
    "deleteHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete parameter(s)"])},
    "disabledHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter details"])},
    "editHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit parameter"])},
    "exportHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export parameter(s)"])},
    "generalParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General parameters"])},
    "moleculeMedium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medium of the molecule"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameters"])}
  },
  "patientHistory": {
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient History"])}
  },
  "peopleToPhone": {
    "doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treating practitioner"])},
    "healthEstablishment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Health establishment"])},
    "nurse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nurse"])},
    "patient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient"])},
    "prescriber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prescriber"])}
  },
  "prescriberSpecialtyCodes": {
    "code0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not specified"])},
    "code1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General medicine"])},
    "code10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neurosurgery"])},
    "code11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otorhinolaryngology"])},
    "code12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pediatrician"])},
    "code13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulmonology"])},
    "code14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rheumatology"])},
    "code15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ophthalmology"])},
    "code16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urology"])},
    "code17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuropsychiatry"])},
    "code18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stomatology"])},
    "code19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dentist"])},
    "code2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anesthesia"])},
    "code20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medical reanimation"])},
    "code21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Midwife"])},
    "code22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialist in general medicine with diploma"])},
    "code23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialist in general medicine recognized by the order"])},
    "code24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male nurse"])},
    "code26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Massage therapist"])},
    "code27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedicure-podiatrist"])},
    "code28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speech Therapist"])},
    "code29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orthoptist"])},
    "code3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cardiology"])},
    "code30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medical analysis laboratory"])},
    "code31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rehabilitation Functional rehabilitation"])},
    "code32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neurology"])},
    "code33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Psychiatry"])},
    "code34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geriatrics"])},
    "code35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nephrology"])},
    "code36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dental surgeon specializing in O.D.F."])},
    "code37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anatomo-cyto-pathology"])},
    "code38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medical Biologist"])},
    "code39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polyvalent medical biology analysis laboratory"])},
    "code4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General surgery"])},
    "code40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biology and pathological cytology analysis laboratory"])},
    "code41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orthopedic surgery"])},
    "code42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endocrinology and Metabolism"])},
    "code43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infant surgery"])},
    "code44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maxillofacial surgery"])},
    "code45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maxillofacial Surgery and Stomatology"])},
    "code46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plastic and aesthetic surgery"])},
    "code47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thoracic Surgery and Vascular Cardiology"])},
    "code48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vascular surgery"])},
    "code49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visceral and Digestive Surgery"])},
    "code5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dermatology-venerology"])},
    "code50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pharmacist"])},
    "code51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mutualist pharmacist"])},
    "code53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dental Surgeon CO"])},
    "code54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MBD Dental Surgeon"])},
    "code6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electroradiology"])},
    "code60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["company supplier"])},
    "code61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["artisan supplier"])},
    "code62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["association supplier"])},
    "code63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orthopaedist-orthotist"])},
    "code64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optician"])},
    "code65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hearing aid professional"])},
    "code66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocularist Epithetist"])},
    "code67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedorthists"])},
    "code68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orthoprosthetists"])},
    "code69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oral surgery"])},
    "code7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obstetric gynecology"])},
    "code70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medical Gynecology"])},
    "code71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hematology"])},
    "code72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuclear medicine"])},
    "code73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medical Oncology"])},
    "code74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radiotherapy Oncology"])},
    "code75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Child Psychiatry"])},
    "code76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radiotherapy"])},
    "code77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obstetrics"])},
    "code78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medical genetics"])},
    "code79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obstetrics Gynecology and Medical Gynecology"])},
    "code8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastroenterology"])},
    "code80": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public health and social medicine"])},
    "code9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal Medicine"])}
  },
  "prescribers": {
    "addHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a prescriber"])},
    "deleteHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete a prescriber"])},
    "disabledHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prescriber details"])},
    "editHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Prescriber"])},
    "exportHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export prescribers"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prescribers"])}
  },
  "rapidSelection": {
    "alcohol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alcohol"])},
    "blood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blood"])},
    "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Another choice"])},
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])},
    "rtPCR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RT-PCR"])}
  },
  "records": {
    "addHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add record"])},
    "addedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["added successfully"])},
    "availableRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available records"])},
    "deleteHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete records"])},
    "disabledHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record details"])},
    "editHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit record"])},
    "exportHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export record(s)"])},
    "hasMultiprescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiprescription?"])},
    "linkedRecordsInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on a record to see the samples associated to it."])},
    "linkedSamplesInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These are the samples and analyses associated to the selected record. Click on any analysis to link it to the current record."])},
    "multiPrescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record has multiple prescriptions"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Records"])},
    "patientDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient details"])},
    "previewRecordTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview record"])},
    "recordAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record added"])},
    "recordLinkedAnalyses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses linked to the record"])},
    "recordReceived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record received successfully"])},
    "samplesToBeLinked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These are the samples and analyses that will be linked to the new record."])},
    "selectLinkedRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select linked records"])},
    "sentExternally": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Sent externally)"])},
    "status0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-recorded"])},
    "status1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recorded"])},
    "status2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing"])},
    "status3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending validation"])},
    "status4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending submission"])},
    "status5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent"])},
    "toPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To pay"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "totalQuote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total quote"])}
  },
  "sample_types": {
    "addHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add sample type"])},
    "deleteHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete sample types"])},
    "disabledHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample type details"])},
    "editHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit sample type"])},
    "exportHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export sample type(s)"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample types"])}
  },
  "samples": {
    "sample_injected_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample injected by"])},
    "sample_prepared_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample prepared by"])},
    "sample_removed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample removed by"])},
    "sample_validated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample validated by"])}
  },
  "scanner": {
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scanner"])}
  },
  "security": {
    "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm password"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password?"])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
    "otpField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable One Time Password via SMS"])},
    "otpText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One Time Password"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security"])},
    "pcField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable ProSante Connect"])},
    "pcText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ProSante Connect"])},
    "phoneNumberRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile number required"])},
    "previousPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous password"])}
  },
  "sortOptions": {
    "asc": {
      "NABM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NABM (ASC)"])},
      "adeli": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adeli (ASC)"])},
      "chapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter (ASC)"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code (ASC)"])},
      "coefB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coef B (ASC)"])},
      "creationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last updated (ASC)"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description (ASC)"])},
      "dob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of birth (ASC)"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail (ASC)"])},
      "family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family (ASC)"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name (ASC)"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID (ASC)"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location (ASC)"])},
      "matrix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrix (ASC)"])},
      "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Method (ASC)"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name (ASC)"])},
      "nonCompliance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-compliance (ASC)"])},
      "observations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Observations (ASC)"])},
      "rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rank (ASC)"])},
      "recordStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record status (ASC)"])},
      "subChapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subchapter (ASC)"])},
      "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name (ASC)"])},
      "wording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wording (ASC)"])}
    },
    "desc": {
      "NABM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NABM (DESC)"])},
      "adeli": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adeli (DESC)"])},
      "chapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter (DESC)"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code (DESC)"])},
      "coefB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coef B (DESC)"])},
      "creationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last updated (DESC)"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description (DESC)"])},
      "dob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of birth (DESC)"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail (DESC)"])},
      "family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family (DESC)"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name (DESC)"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID (DESC)"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location (DESC)"])},
      "matrix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrix (DESC)"])},
      "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Method (DESC)"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name (DESC)"])},
      "nonCompliance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-compliance (DESC)"])},
      "observations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Observations (DESC)"])},
      "rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rank (DESC)"])},
      "recordStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record status (DESC)"])},
      "subChapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subchapter (DESC)"])},
      "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name (DESC)"])},
      "wording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wording (DESC)"])}
    }
  },
  "steppers": {
    "records": {
      "initScreen": {
        "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please choose what you would like to do"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage record"])}
      },
      "steps": {
        "1": {
          "btnInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the buttons below to link related records or to skip this step and create a new patient"])},
          "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the search bar below to select an existing patient"])},
          "patientSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search patients by name or insurance number"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select or create patient"])}
        },
        "10": {
          "btnInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional information"])}
        },
        "11": {
          "btnInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record analyses"])}
        },
        "12": {
          "btnInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special considerations"])}
        },
        "2": {
          "btnInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic patient information"])}
        },
        "3": {
          "btnInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient physiology"])}
        },
        "4": {
          "btnInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Health insurance details"])}
        },
        "5": {
          "btnInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient contact details"])}
        },
        "6": {
          "btnInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient additional information"])}
        },
        "7": {
          "btnInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new resources if you need to"])},
          "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record collection details"])}
        },
        "8": {
          "btnInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clinical history and posology"])}
        },
        "9": {
          "btnInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report options"])}
        }
      },
      "summary": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record summary"])}
      }
    }
  },
  "tabTitle": {
    "accountSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account settings - Ninja LIMS"])},
    "addRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add record - Ninja LIMS"])},
    "analyses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis - Ninja LIMS"])},
    "appearanceSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appearance settings - Ninja LIMS"])},
    "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing - Ninja LIMS"])},
    "clinics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clinics - Ninja LIMS"])},
    "collectors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collectors - Ninja LIMS"])},
    "currentUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current user - Ninja LIMS"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard - Ninja LIMS"])},
    "dataManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data management - Ninja LIMS"])},
    "doctors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prescriber dashboard - Ninja LIMS"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error - Ninja LIMS"])},
    "external_records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External dashboard - Ninja LIMS"])},
    "families": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Families - Ninja LIMS"])},
    "helpSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help settings - Ninja LIMS"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import - Ninja LIMS"])},
    "informationSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information settings - Ninja LIMS"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login - Ninja LIMS"])},
    "machines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machines - Ninja LIMS"])},
    "matrices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrices - Ninja LIMS"])},
    "nabms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NABMs - Ninja LIMS"])},
    "notificationSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification settings - Ninja LIMS"])},
    "parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameters - Ninja LIMS"])},
    "patients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patients - Ninja LIMS"])},
    "plainNinja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ninja LIMS"])},
    "prescribers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prescribers - Ninja LIMS"])},
    "previewRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview record - Ninja LIMS"])},
    "records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Records - Ninja LIMS"])},
    "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password - Ninja LIMS"])},
    "sample_types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample types - Ninja LIMS"])},
    "scanner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scanner - Ninja LIMS"])},
    "securitySettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security settings - Ninja LIMS"])},
    "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasks"])},
    "techniques": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samples - Ninja LIMS"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users - Ninja LIMS"])}
  },
  "tables": {
    "analysesSearchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by analysis name, NABM or matrix name"])},
    "analysisRequestForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis request form"])},
    "as": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["as:"])},
    "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing"])},
    "collectorSearchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by collector name or surname"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
    "exportAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export all in database as"])},
    "exportFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export format"])},
    "familiesSearchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by surname"])},
    "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["item(s)"])},
    "linesPerPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lines per page"])},
    "machineSearchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by machine name"])},
    "matricesSearchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by matrix name"])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
    "nabmsSearchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by NABM code, Coef B or NABM wording"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on"])},
    "parametersSearchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by parameter rank, name, machine Code, machine or family"])},
    "patientSearchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by patient name, surname, social security or record ID"])},
    "prescriberSearchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by prescriber first name, surname or ADELI"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "printBarcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print barcode label"])},
    "printExternalLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print external label"])},
    "printLabels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print labels"])},
    "printPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print password label"])},
    "recordSearchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by patient name, surname, social security or record ID"])},
    "sampleTypeSearchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by sample type name"])},
    "scanPrescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan prescription"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search in this table"])},
    "techniqueSearchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by ID, machine or sample type"])}
  },
  "tabs": {
    "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default"])},
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
    "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])}
  },
  "tasks": {
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasks"])}
  },
  "techniques": {
    "addHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a technique"])},
    "deleteHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete technique"])},
    "disabledHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details of the technique"])},
    "editHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit technique"])},
    "exportHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export techniques"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Techniques"])},
    "sampleObservations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample observations"])},
    "techniqueModalHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manually update sample"])},
    "techniqueResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results"])}
  },
  "toasts": {
    "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What are you doing??"])},
    "alreadyInUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone/email unavailable"])},
    "alreadyVerified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already verified"])},
    "cannotDowngradeStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot downgrade status"])},
    "checkInsIdentity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identity successfully verified"])},
    "downloadSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File downloaded!"])},
    "emailPendingVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email unavailable"])},
    "endValidationRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No more records left to validate"])},
    "errorCheckInputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error! Some of your entries are not valid"])},
    "expiredToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expired token"])},
    "fetchedRecordFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retrieved stored record files"])},
    "fileDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File deleted"])},
    "fileUploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File uploaded successfully!"])},
    "getINS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully retrieved INS information"])},
    "insNIA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The INS is of the NIA type"])},
    "insufficientRights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insufficient rights"])},
    "insuranceExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning! The patient's insurace has expired"])},
    "insuranceExpiringSoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning! The patient's insurace will expire soon"])},
    "invalidCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid credentials"])},
    "invalidDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid date"])},
    "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid email"])},
    "invalidInputType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some of your entries are not valid"])},
    "invalidLocale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid locale"])},
    "invalidPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid password"])},
    "invalidPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid mobile number"])},
    "invalidSortName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid sort name"])},
    "invalidStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid status"])},
    "invalidTableName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid table name"])},
    "invalidTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid title"])},
    "invalidToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid token"])},
    "invalidUUID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid UUID"])},
    "invalidatedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalidated successfully"])},
    "missingData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing data"])},
    "missingResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The sample is missing one or more results, are you sure you want to mark it as complete? If you continue, you will have to invalidate all samples related to the record to be able to edit the results."])},
    "movingRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loaded next record to validate"])},
    "multipleInsIdentity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple identities found"])},
    "mustChooseCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country not selected"])},
    "nabmAlreadyInUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NABM code cannot be used"])},
    "ncaAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NCA added!"])},
    "noAdminRights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No admin rights"])},
    "noInsIdentity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No identity found"])},
    "otpExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP has expired, request a new one"])},
    "otpNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No OTP found for your account"])},
    "passwordComplexity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must be at least eight characters long, including at least one letter, one number and one special character."])},
    "passwordsDoNotMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords do not match"])},
    "phonePendingVerOrLinking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify your mobile number first!"])},
    "precomputeSucceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results calculated successfully!"])},
    "printSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File printed!"])},
    "recordAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record added successfully"])},
    "recordAlreadyScanned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record already scanned"])},
    "recordDoesNotExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record does not exist"])},
    "recordEdited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record edited"])},
    "resultsImportSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results imported successfully!"])},
    "sampleUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample updated successfully"])},
    "somethingWentWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong!"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful"])},
    "successAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully added"])},
    "successRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully requested"])},
    "successUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update successful"])},
    "totallyWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went totally wrong!"])},
    "unexpectedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unexpected error! Try reloading the page."])},
    "userDoesNotExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account does not exist"])},
    "validatedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validated successfully"])},
    "verCodeNotExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link is invalid"])},
    "verLinkExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link has expired"])}
  },
  "tooltips": {
    "addNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new"])},
    "addRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add record"])},
    "billingDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show billing details"])},
    "cerfa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerfa"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "externalLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print external label"])},
    "filterResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter results"])},
    "invalidateRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalidate record"])},
    "invalidateSamples": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalidate samples"])},
    "moreOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More options"])},
    "prescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prescription"])},
    "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
    "printBarcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print barcode label"])},
    "printPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print password"])},
    "recordSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show summary"])},
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh"])},
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])},
    "requestForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get external lab request form"])},
    "scanPrescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan prescription"])},
    "showHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show historical results"])},
    "tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracking"])},
    "uploadDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload documents"])},
    "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate"])}
  },
  "trace": {
    "detailed_trace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detailed trace"])},
    "log_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log comment"])},
    "modification_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "modification_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field"])},
    "modification_new_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New value"])},
    "modification_old_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old value"])},
    "modification_timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date and time"])},
    "modification_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
    "nca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NCA"])}
  },
  "uploads": {
    "appearsInReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appears in report?"])},
    "appendToReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Append this file to record report"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "existingFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Existing files for record"])},
    "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File name"])},
    "fileType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File type"])},
    "fileTypeSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the type of document you would like to upload"])},
    "fileTypes": {
      "driversLicense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PATIENT - Driver's License"])},
      "insuranceCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PATIENT - Insurance card"])},
      "misc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RECORD - Miscellaneous"])},
      "nationalIdCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PATIENT - National ID card"])},
      "other_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PATIENT - Other form of ID"])},
      "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PATIENT - Passport"])},
      "patient_misc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PATIENT - Other files"])},
      "prescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RECORD - Prescription"])},
      "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RECORD - Results"])}
    },
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploads"])},
    "scanFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan file"])},
    "scanOrSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please scan or select a file to upload"])},
    "seeUploads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show record uploads"])},
    "uploadDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload date"])},
    "uploadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload file"])},
    "uploadRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a record to upload documents for"])}
  },
  "users": {
    "addHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add user"])},
    "deleteHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete user"])},
    "disabledHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User details"])},
    "editHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit user"])},
    "exportHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export users"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
    "types": {
      "external": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External"])},
      "internal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal"])},
      "prescriber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prescriber"])}
    }
  }
}